import { getI18n } from 'react-i18next';
import language from './language';

const DidomiPreferences = () => {
  const { language: lang } = getI18n();
  const translation = language[lang];

  const handleOnClick = () => {
    window.Didomi && window.Didomi.preferences.show();
  };

  return (
    <span
      style={{ cursor: 'pointer' }}
      onClick={handleOnClick}
      role="link"
      tabIndex="0"
      data-testid="button-manage-gdpr-preferences"
    >
      {translation.managePrivacySettings}
    </span>
  );
};

export default DidomiPreferences;
