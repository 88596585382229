const base = {
  [LANG.NL]: {
    country: 'Land',
  },
  [LANG.DE]: {
    country: 'Land',
  },
  [LANG.EN]: {
    country: 'Country',
  },
  [LANG.FR]: {
    country: 'Pays',
  },
  [LANG.PT]: {
    country: 'País',
  },
};

export default base;
