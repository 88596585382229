const base = {
  [LANG.NL]: {
    box: 'Bus',
    street: 'Straat',
    city: 'Woonplaats',
    optional: 'Optioneel',
    addition: 'Toevoeging',
    postalCode: 'Postcode',
    houseNumber: 'Huisnummer',
  },
  [LANG.DE]: {
    box: 'Bus',
    street: 'Straße',
    city: 'Stadt',
    optional: 'Optional',
    addition: 'Hinzufügen Auf',
    postalCode: 'Postleitzahl',
    houseNumber: 'Hausnummer',
  },
  [LANG.EN]: {
    box: 'Box',
    street: 'Street',
    city: 'City',
    optional: 'Optional',
    addition: 'Addition',
    postalCode: 'Postal code',
    houseNumber: 'House number',
  },
  [LANG.FR]: {
    box: 'Boîte',
    street: 'Rue',
    city: 'Ville',
    optional: 'Facultatif',
    addition: 'Addition',
    postalCode: 'Code postal',
    houseNumber: 'Numéro de maison',
  },
  [LANG.PT]: {
    box: 'Caixa',
    street: 'Rua',
    city: 'Cidade',
    optional: 'Opcional',
    addition: 'Adição',
    postalCode: 'Código postal',
    houseNumber: 'N°/Andar',
  },
};

export default base;
