/* eslint-disable import/max-dependencies */
import 'antd/dist/antd.min.css';
import 'url-search-params-polyfill';

import { Flex } from '@mediahuis/chameleon-react';
import '@mediahuis/chameleon-reset';
import { lazy, Suspense } from 'react';
import { Wizard } from 'react-albus/lib';
import { hot } from 'react-hot-loader/root';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { Didomi, Footer, TopBar } from '~/components';
import StaticRender from '~/components/StaticRender';
import { CheckoutProvider } from '~/context/checkout';
import { ConfigurationProvider } from '~/context/configuration';
import { GlobalProvider } from '~/context/global';
import { InsightsProvider } from '~/context/insights';
import { OfferProvider } from '~/context/offer';
import { OnlineStatusProvider } from '~/hooks/useOnlineStatus';
import { GlobalStyle } from '~/styles';

import { AuthenticationProvider } from './authentication';
import PageLoader from './components/PageLoader';
import Maintenance from './components/Maintenance';
import './i18n';
import WindowProvider from './components/WindowProvider';

const Offer = lazy(() => import('~/pages/Offer'));
const Renewal = lazy(() => import('~/pages/Renewal'));
const Access = lazy(() => import('~/pages/Access'));
const NotFound = lazy(() => import('~/pages/NotFound'));
const Checkout = lazy(() => import('~/pages/Checkout'));
const Settlement = lazy(() => import('~/pages/Settlement'));
const Smart = lazy(() => import('~/pages/Smart'));
const VoucherPrint = lazy(() => import('~/pages/Voucher/VoucherPrint'));
const VoucherInput = lazy(() => import('~/pages/Voucher/VoucherInput'));

const App = ({ staticRender = false }) => (
  <ConfigurationProvider>
    <Router>
      <Suspense fallback={<PageLoader />}>
        <AuthenticationProvider>
          <WindowProvider>
            <GlobalStyle />
            <GlobalProvider staticRender={staticRender}>
              {!staticRender && <Didomi />}
              <Flex
                flexDirection="column"
                justifyContent="space-between"
                style={{ minHeight: '100vh' }}
              >
                <OnlineStatusProvider>
                  <TopBar />
                  <Maintenance>
                    <StaticRender staticRender={staticRender}>
                      <OfferProvider>
                        <Switch>
                          <Route path="/404" component={NotFound} />
                          <Route path="/access" component={Access} />
                          <Route
                            path="/checkout"
                            render={({ history, match: { url } }) => (
                              <Wizard history={history} basename={url}>
                                {({ step, steps }) => (
                                  <InsightsProvider>
                                    <CheckoutProvider>
                                      <Checkout step={step} steps={steps} />
                                    </CheckoutProvider>
                                  </InsightsProvider>
                                )}
                              </Wizard>
                            )}
                          />
                          <Route path="/renewal" component={Renewal} />
                          <Route path="/winback" component={Renewal} />
                          <Route path="/settlement" component={Settlement} />
                          <Route path="/smart" component={Smart} />
                          <Route path="/voucher" component={VoucherPrint} />
                          <Route path="/cadeau" component={VoucherInput} />
                          <Route path="*" component={Offer} />
                        </Switch>
                      </OfferProvider>
                    </StaticRender>
                  </Maintenance>
                  <Footer />
                </OnlineStatusProvider>
              </Flex>
            </GlobalProvider>
          </WindowProvider>
        </AuthenticationProvider>
      </Suspense>
    </Router>
  </ConfigurationProvider>
);

export default hot(App);
