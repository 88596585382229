import { createContext, useContext, useEffect, useState } from 'react';
import { useGlobalContext } from '~/context/global';

const DEFAULT_VALIDATIONS = {
  choiceValidation: [],
  textFieldValidation: [],
};

const CheckoutContext = createContext();

const CheckoutProvider = ({ children }) => {
  const { user } = useGlobalContext();

  const [validations, setValidations] = useState(DEFAULT_VALIDATIONS);

  const [initiallyHasFirstName, setInitiallyHasFirstName] = useState(
    Boolean(user?.firstName),
  );
  const [initiallyHasName, setInitiallyHasName] = useState(Boolean(user?.name));
  const [paymentOptionCount, setPaymentOptionCount] = useState(0);

  const initValidations = order => {
    setValidations({
      addressAreaValidation: order.addressAreaValidations || [],
      boolValidation: order.booleanValidations || [],
      choiceValidation: order.choiceValidations || [],
      dateValidation: order.dateValidations || [],
      emailValidation: order.emailValidations || [],
      fileValidation: order.fileValidations || [],
      invoiceValidation: order.invoiceDetailsValidation,
      loginValidation: order.loginValidation,
      paymentValidation: order.paymentValidation,
      preconditionValidation: order.preconditionsValidation,
      textFieldValidation: order.textfieldValidations || [],
      voucherValidation: order.voucherValidation,
    });
  };

  useEffect(() => {
    if (validations.paymentValidation) {
      const oneShotOptionsCount =
        validations.paymentValidation.oneShotPaymentOptions.length;
      const recurringOptionsCount =
        validations.paymentValidation.recurringPaymentOptions.length;

      setPaymentOptionCount(oneShotOptionsCount + recurringOptionsCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validations.paymentValidation]);

  const value = {
    validations,
    initValidations,
    initiallyHasFirstName,
    initiallyHasName,
    setInitiallyHasFirstName,
    setInitiallyHasName,
    paymentOptionCount,
  };

  return (
    <CheckoutContext.Provider value={value}>
      {children}
    </CheckoutContext.Provider>
  );
};

const useCheckoutContext = () => {
  const context = useContext(CheckoutContext);

  if (context === undefined) {
    throw new Error(
      'useCheckoutContext must be used within a CheckoutProvider',
    );
  }

  return context;
};

export { CheckoutProvider, useCheckoutContext };
