import Downshift from 'downshift';
import { TextField } from '@mediahuis/chameleon-react';
import {
  colorGrey30,
  colorWhiteBase,
  fontWeightBold,
  fontWeightRegular,
  fontFamilySystem,
} from '@mediahuis/chameleon-theme-wl';
import FormGroup from '../../FormGroup';
import SuggestionWrapper from '../../SuggestionWrapper';
import SuggestionItem from '../../SuggestionItem';

const StreetAutoComplete = ({
  onChange,
  itemToString,
  onInputValueChange,
  label,
  streets,
  street,
  errors,
  validateOnBlur,
  clearError,
  disabled = false,
  autoComplete = '',
}) => (
  <Downshift
    onChange={onChange}
    itemToString={itemToString}
    onInputValueChange={onInputValueChange}
    initialSelectedItem={street}
    stateReducer={(state, changes) => {
      // We need to check if the changes made
      // are of a valid type or undefined to know
      // how to update the state which uses this component
      if (changes.inputValue === undefined) {
        return {
          ...changes,
          inputValue: street,
        };
      }
      return changes;
    }}
  >
    {({
      getInputProps,
      getItemProps,
      getMenuProps,
      getRootProps,
      isOpen,
      inputValue,
      highlightedIndex,
      selectedItem,
      clearSelection,
    }) => (
      <FormGroup {...getRootProps({ refKey: 'innerRef' })}>
        <TextField
          {...getInputProps({
            onChange: e => {
              if (e.target.value !== street) {
                clearSelection();
              }
            },
            onBlur: e => {
              // Event does not work as expected with Downshift,
              // we pass a custom object with eiter an empty
              // string or selected object.
              const validStreet = streets.find(s => s.Name === e.target.value);
              const downshiftValues = {
                target: {
                  id: 'street',
                  value: validStreet ? street : '',
                },
              };
              validateOnBlur(downshiftValues);
            },
          })}
          autoComplete={autoComplete}
          data-testid="street-autocomplete"
          disabled={disabled}
          error={!!errors.street}
          id="street"
          label={label}
          maxlength={255}
          message={errors.street}
          value={street}
          onFocus={clearError}
        />
        <SuggestionWrapper {...getMenuProps()}>
          {isOpen &&
            streets
              .filter(
                cStreet =>
                  !inputValue ||
                  cStreet.Name.toLowerCase().includes(inputValue.toLowerCase()),
              )
              .map((item, index) => (
                <SuggestionItem
                  key={item}
                  {...getItemProps({
                    key: item.StreetId,
                    index,
                    item,
                    style: {
                      backgroundColor:
                        highlightedIndex === index
                          ? colorGrey30
                          : colorWhiteBase,
                      fontWeight:
                        selectedItem === item
                          ? fontWeightBold
                          : fontWeightRegular,
                      fontFamily: fontFamilySystem,
                    },
                  })}
                >
                  {item.Name}
                </SuggestionItem>
              ))}
        </SuggestionWrapper>
      </FormGroup>
    )}
  </Downshift>
);

export default StreetAutoComplete;
