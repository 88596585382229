import { Fragment, useEffect } from 'react';
import { getI18n } from 'react-i18next';
import { Box, Flex, TextField } from '@mediahuis/chameleon-react';
import PostalCodeAutoComplete from './autocomplete/PostalCodeAutoComplete';
import useCity from './useCity';
import HouseNumberTextField from './components/HouseNumberTextField';
import language from './language';

const DutchAddresFields = ({
  onChange,
  values,
  clearFields,
  errors = {},
  validateOnBlur,
  clearError,
  streets,
}) => {
  const { language: selectedLanguage } = getI18n();
  const translation = language[selectedLanguage];
  const { postalCode, city, street, houseNumber, box } = values;
  const formFields = ['houseNumber', 'box', 'street', 'city', 'box'];
  const suggestionItems = useCity(
    // Remove possible spacing of postalCode
    // and set spacing between automatically so values
    // like 6211rv become 6211 rv.
    postalCode?.replace(/\s+/g, '').replace(/(\d{4})(\D)/g, '$1 $2'),
    'NL',
  );

  const resetFields = value => {
    if (value !== postalCode && postalCode !== '') {
      // emptying array by removing index 0
      // so all street suggestions get removed
      [streets].splice(0, 1);
      clearFields(formFields);
    }
  };

  useEffect(() => {
    if (street !== streets && houseNumber !== '') {
      onChange('street', streets);
    } else if (houseNumber === '' && street !== '') {
      onChange('street', '');
    }
  }, [houseNumber, onChange, street, streets]);

  return (
    <Fragment>
      <Box pb={4}>
        <Box pb={4} flex={3}>
          <PostalCodeAutoComplete
            onChange={selection => {
              if (selection && selection.PostalCode && selection.Name) {
                onChange('postalCode', selection.PostalCode);
                onChange('city', selection.Name);
                const cityEvent = { target: { id: 'city' } };
                clearError(cityEvent);
              }
            }}
            itemToString={item => (item ? item.PostalCode : '')}
            onInputValueChange={value => {
              resetFields(value);
              onChange('postalCode', value);
            }}
            postalCode={postalCode}
            label={translation.postalCode}
            items={suggestionItems}
            errors={errors}
            streets={[streets]}
            validateOnBlur={validateOnBlur}
            clearError={clearError}
            autoComplete="new-postalCode"
          />
        </Box>
        <Flex>
          <Box flex={2}>
            <Flex>
              <Box pb={2} mr={3} flex={1}>
                <HouseNumberTextField
                  validateOnBlur={validateOnBlur}
                  clearError={clearError}
                  houseNumber={houseNumber}
                  errors={errors}
                  disabled={!postalCode}
                  onChange={onChange}
                />
              </Box>
              <Box flex={1}>
                <TextField
                  autoComplete="new-box"
                  data-testid="input-box"
                  disabled={!postalCode}
                  error={!!errors.box}
                  id="box"
                  label={translation.addition}
                  maxlength={MH_GROUP === 'LUX' ? 5 : 50}
                  message={errors.box}
                  optionalLabel={translation.optional}
                  value={box || ''}
                  onBlur={validateOnBlur}
                  onChange={event =>
                    onChange(event.target.id, event.target.value)
                  }
                  onFocus={clearError}
                />
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Box>
      <Box pb={4} flex={2}>
        <TextField
          autoComplete="new-city"
          data-testid="input-city"
          disabled
          id="city"
          label={translation.city}
          maxlength={255}
          value={city}
        />
      </Box>
      <Box pb={6}>
        <TextField
          autoComplete="new-street"
          data-testid="input-street"
          disabled
          id="street"
          label={translation.street}
          maxlength={255}
          value={street}
        />
      </Box>
    </Fragment>
  );
};

export default DutchAddresFields;
