import { Flex, Loader } from '@mediahuis/chameleon-react';
import { CONTENT_HEIGHT } from '~/constants/theme';

const PageLoader = () => (
  <Flex
    alignItems="center"
    justifyContent="center"
    style={{ height: CONTENT_HEIGHT }}
  >
    <Loader />
  </Flex>
);

export default PageLoader;
