import { Box } from '@mediahuis/chameleon-react';
import { isMobile } from 'react-device-detect';
import FooterMobile from './FooterMobile';
import FooterDesktop from './FooterDesktop';

const Footer = () => (
  <Box mt={7}>{isMobile ? <FooterMobile /> : <FooterDesktop />}</Box>
);

export default Footer;
