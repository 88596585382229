import { Flex, Illustration } from '@mediahuis/chameleon-react';
import { SaveFile } from '@mediahuis/chameleon-theme-wl/illustrations';

import { getI18n, useTranslation } from 'react-i18next';

import { useConfigurationContext } from '~/context/configuration';

import { language } from './language';

const Maintenance = ({ children }) => {
  useTranslation();
  const { language: selectedLanguage } = getI18n();
  const { Title, Subtitle } = language[selectedLanguage];

  const { maintenance } = useConfigurationContext();

  if (maintenance) {
    return (
      <Flex alignItems="center" justifyContent="center" flexDirection="column">
        <Illustration as={SaveFile} width="400px" maxWidth="full" />
        <Title textAlign="center" mt={2} />
        <Subtitle textAlign="center" mt={2} width="704px" maxWidth="full" />
      </Flex>
    );
  }

  return children;
};

export default Maintenance;
