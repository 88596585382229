import { getI18n } from 'react-i18next';
import { TextField } from '@mediahuis/chameleon-react';
import { formatHouseNumber } from '~/utils/';
import language from '../language';

const HouseNumberTextField = ({
  validateOnBlur,
  clearError,
  houseNumber,
  errors,
  disabled,
  onChange,
}) => {
  const { language: selectedLanguage } = getI18n();
  const translation = language[selectedLanguage];

  return (
    <TextField
      autoComplete="new-houseNumber"
      data-testid="input-houseNumber"
      disabled={disabled}
      error={!!errors.houseNumber}
      id="houseNumber"
      label={translation.houseNumber}
      maxlength={50}
      message={errors.houseNumber}
      value={houseNumber}
      onBlur={validateOnBlur}
      onChange={event => {
        const { formattedHouseNumber } = formatHouseNumber(event.target.value);

        onChange(event.target.id, formattedHouseNumber);
      }}
      onFocus={clearError}
    />
  );
};

export default HouseNumberTextField;
