import { createContext, useContext, useEffect, useState } from 'react';

import getConfiguration from '~/utils/getConfiguration';

const defaultConfig = {
  maintenance: false,
};

const ConfigurationContext = createContext(defaultConfig);

const ConfigurationProvider = ({ children }) => {
  const [configuration, setConfiguration] = useState(defaultConfig);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getConfiguration({
      onSuccess: response => {
        setConfiguration(response[MH_BRAND] || defaultConfig);
        setIsLoading(false);
      },
      onError: () => {
        setConfiguration(defaultConfig);
        setIsLoading(false);
      },
    });
  }, []);

  return (
    <ConfigurationContext.Provider value={configuration}>
      {isLoading ? null : children}
    </ConfigurationContext.Provider>
  );
};

const useConfigurationContext = () => {
  const context = useContext(ConfigurationContext);

  if (!context) {
    throw new Error(
      'useConfigurationContext must be used within an ConfigurationProvider',
    );
  }

  return context;
};

export { ConfigurationProvider, useConfigurationContext };
