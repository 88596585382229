import { useEffect, useContext, createContext, useMemo } from 'react';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const InsightsContext = createContext();

const InsightsProvider = ({ children }) => {
  const reactPlugin = new ReactPlugin();
  const appInsights = useMemo(
    () =>
      new ApplicationInsights({
        config: {
          instrumentationKey: INSIGHT_KEY,
          extensions: [reactPlugin],
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const value = { appInsights };

  useEffect(() => {
    appInsights.loadAppInsights();
  }, [appInsights]);

  return (
    <InsightsContext.Provider value={value}>
      {children}
    </InsightsContext.Provider>
  );
};

const useInsightsContext = () => {
  const context = useContext(InsightsContext);

  if (context === undefined) {
    throw new Error(
      'useInsightsContext must be used within an InsightsProvider',
    );
  }

  return context;
};

export { InsightsProvider, useInsightsContext };
