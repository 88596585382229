import { Box, Flex, Logo, Select } from '@mediahuis/chameleon-react';
import { colorGrey10 } from '@mediahuis/chameleon-theme-wl';
import { CustomerServiceWidget } from '@mediahuis/subscriber-react';
import { isMobile } from 'react-device-detect';
import { getI18n } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useConfigurationContext } from '~/context/configuration';
import { CHECKOUT_FLOW, CHECKOUT30_BACK_URL } from '~/constants';
import { getCheckout30, getSettlement30 } from '~/constants/checkout';
import { useGlobalContext } from '~/context/global';
import goHome from '~/utils/goHome';
import Wrapper from '../Wrapper';
import { language } from './language';

const SelectWithouthBorder = styled(Select)`
  #language {
    border: none;
    background-color: ${colorGrey10};
    &:focus,
    &:active {
      box-shadow: none !important;
      border: none;
    }

    ${props =>
    props.disabled &&
    `
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: initial;
    `}
  }
`;

export const LOGO_VERSIONS = {
  [BRANDS.GVA]: 'v2',
  [BRANDS.HBVL]: 'v2',
  [BRANDS.DS]: 'v2',
  [BRANDS.DL]: 'v1',
  [BRANDS.NB]: 'v2',
};

const srConfig = {
  siteUrl: SITE_URL,
  brandNameFull: SITE_NAME,
  logoVersion: LOGO_VERSIONS[MH_BRAND],
  urlPrefix: URL_PREFIX,
  apiPrefix: API_PREFIX,
  auth0ClientId: CIAM_CLIENT_ID,
};

const TopBar = () => {
  const { language: lang } = getI18n();
  const translation = language[lang];

  const history = useHistory();

  const {
    user,
    setLanguage,
    staticRender,
    setGlobalState,
  } = useGlobalContext();
  const { isChatbotRobbie } = useConfigurationContext();

  const isLangSelectDisabled = () => {
    const isCheckout = window.location.pathname.includes('checkout');

    switch (true) {
      case isCheckout:
        return true;
      default:
        return false;
    }
  };

  const handleGoHome = () => {
    const isCheckout30 =
      window.location.pathname.includes(getCheckout30(lang)?.id) ||
      window.location.pathname.includes(getSettlement30(lang)?.id);

    // reset checkoutFlow to default value
    sessionStorage.removeItem(CHECKOUT_FLOW);
    sessionStorage.removeItem(CHECKOUT30_BACK_URL);
    setGlobalState({ checkoutFlow: null });
    goHome(history, {}, { isCheckout30 });
  };

  return (
    <Box bgColor="colorGrey10" p={0} height={isMobile ? 9 : 10}>
      <Wrapper>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          height={isMobile ? 9 : 10}
          style={{ gap: '2rem' }}
        >
          <Logo
            data-testid="button-home"
            extension="svg"
            // eslint-disable-next-line no-nested-ternary
            height={MH_BRAND === 'az' ? 7 : isMobile ? 9 : 10}
            maxWidth={MH_BRAND === 'az' ? 12 : 11}
            name={
              MH_BRAND === 'tc' || MH_BRAND === 'az'
                ? 'brand-main'
                : 'brand-square-main'
            }
            style={{ cursor: 'pointer' }}
            onClick={handleGoHome}
          />
          <Flex alignItems="center">
            {/**
            TODO make cleaner solution for CustomerServiceWidget Lux exception. Maybe check with business if they want to use some of the customer service components. However this doesn't work with new brands out of the box. */}
            {!isMobile &&
              !staticRender &&
              (MH_GROUP === GROUPS.BE || MH_GROUP === GROUPS.DL) && (
                <Box px={5}>
                  <CustomerServiceWidget
                    user={user}
                    title={translation.contactCustomerService}
                    dropdownAlignment="right"
                    destination="aboshop"
                    config={{ ...srConfig, isChatbotRobbie }}
                  />
                </Box>
              )}
            {POSSIBLE_LANGUAGES[MH_BRAND]?.length > 1 && (
              <Box>
                <SelectWithouthBorder
                  value={lang}
                  id="language"
                  labelHidden={true}
                  onChange={setLanguage}
                  disabled={isLangSelectDisabled()}
                >
                  {POSSIBLE_LANGUAGES[MH_BRAND].map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </SelectWithouthBorder>
              </Box>
            )}
          </Flex>
        </Flex>
      </Wrapper>
    </Box>
  );
};

export default TopBar;
