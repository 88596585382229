import { Box, Paragraph } from '@mediahuis/chameleon-react';
import { useCountryName } from '~/utils/getCountries';

const AccountDetails = ({ accountDetails }) => {
  const {
    country,
    email,
    postalCode,
    city,
    street,
    houseNumber,
    box,
    firstName,
    name,
  } = accountDetails;
  const countryName = useCountryName({ countryCode: country });

  return (
    <Box pb={3}>
      {(firstName || name) && (
        <Paragraph>
          {firstName && `${firstName} `}
          {name && name}
        </Paragraph>
      )}
      {email && <Paragraph>{email}</Paragraph>}
      {street && (
        <Paragraph>
          {street} {houseNumber}
          {box && '/'}
          {box}
        </Paragraph>
      )}
      {(postalCode || city) && (
        <Paragraph>
          {postalCode} {city}
        </Paragraph>
      )}
      {countryName && <Paragraph>{countryName}</Paragraph>}
    </Box>
  );
};

export default AccountDetails;
