import { useAuthentication } from '../../hooks/useAuthentication';

import { AUTHENTICATION_STATUS } from '../../enums';

const SignedOut = ({ children }) => {
  const { authenticationStatus } = useAuthentication();

  if (authenticationStatus === AUTHENTICATION_STATUS.UNAUTHENTICATED) {
    return children;
  }

  return null;
};

export default SignedOut;
