import { de, nl, fr, pt } from 'date-fns/locale';

export default language => {
  switch (language) {
    case LANG.NL:
      return nl;
    case LANG.DE:
      return de;
    case LANG.FR:
      return fr;
    case LANG.PT:
      return pt;
    default:
      return '';
  }
};
