import { getI18n } from 'react-i18next';
import styled from 'styled-components';
import {
  Divider,
  Paragraph,
  LinkText,
  Caption,
} from '@mediahuis/chameleon-react';
import { colorBlue50, colorBlackBase } from '@mediahuis/chameleon-theme-wl';
import handleExternalLink from '~/utils/handleExternalLink';
import { useGlobalContext } from '~/context/global';
import DidomiPreferences from '../didomi/DidomiPreferences';
import language from './language';
import { Bold } from '../Bold';

const LinkWithTextDecoration = styled(LinkText)`
  color: ${colorBlue50};
  text-decoration: underline;
`;

const Link = styled(LinkText)`
  color: ${colorBlackBase} !important;
  text-decoration: none;
  &&:hover {
    text-decoration: none;
  }
`;

const Wrapper = styled.div`
  ${props =>
    props.marginBottom ? `margin-bottom: ${props.marginBottom}px` : 0};
`;

const FooterMobile = () => {
  const { language: selectedLanguage } = getI18n();
  const translation = language[selectedLanguage];
  const { UI } = useGlobalContext();

  const handleClick = () => {
    switch (MH_BRAND) {
      case 'az':
        return handleExternalLink(
          'https://service.medienhausaachen.de/kundenportal/leserservice',
        );
      case 'lw':
      case 'tc':
      case 'lt':
      case 'co':
        return handleExternalLink(
          `https://abo.wort.lu/contact?lng=${selectedLanguage}`,
        );
      default:
        return handleExternalLink(
          `https://${URL_PREFIX}.${SITE_URL}/service#contacteer`,
        );
    }
  };

  return (
    <Wrapper marginBottom={UI.extraFooterHeight}>
      <Divider />
      {MH_BRAND !== 'lt' && (
        <>
          <Paragraph py={4} textAlign="center">
            {`${translation.footerTitle} `}
            <LinkWithTextDecoration
              to=""
              onClick={() => handleClick()}
              data-testid="button-customer-service-contact"
            >
              {translation.contactCustomerService}
            </LinkWithTextDecoration>
          </Paragraph>
          <Divider />
          <Paragraph pt={4} pb={2} textAlign="center">
            <DidomiPreferences />
          </Paragraph>
          <Paragraph pb={MH_BRAND === 'dl' ? 2 : 7} textAlign="center">
            <Link
              href={translation.privacyPolicyUrl}
              target="_blank"
              data-testid="button-privacy-policy"
            >
              {translation.privacyPolicy}
            </Link>
          </Paragraph>

        </>)}
      {MH_BRAND === 'lt' && (
        <>
          <Paragraph ml={3} py={4}>
            {translation.footerContactPhoneDesktop}
          </Paragraph>
          <Paragraph ml={3}>
            <Bold>
              <a href={`tel:${translation.customerServicePhoneNumber}`}>
                {translation.customerServicePhoneNumber}
              </a>
            </Bold>
          </Paragraph>
          <Paragraph ml={3} py={4}>
            {translation.footerContactPhoneClosingHourDesktop}
          </Paragraph>
          <Paragraph ml={3} py={4}>
            <a href={`mailto:${translation.customerServiceEmail}`}>
              {translation.customerServiceEmail}
            </a>
          </Paragraph>

        </>)}

      {MH_BRAND === 'dl' && (
        <Paragraph pb={7} textAlign="center">
          <Link
            href={translation.termsLink}
            target="_blank"
            data-testid="button-algemene-voorwaarden"
          >
            {translation.terms}
          </Link>
        </Paragraph>
      )}
      {MH_GROUP === GROUPS.BE && (
        <Paragraph pb={7} textAlign="center">
          {translation.mediahuisText}
          <img
            alt="Mediahuis Logo"
            src="https://focus.mediahuisgroup.com/raw/prod-mh-design-system/4.3.0/mh/logos/brand-main.svg"
            width="150px"
            style={{ 'margin-top': 'var(--space-4)' }}
          />
        </Paragraph>
      )}
      {MH_BRAND === 'dl' && (
        <Paragraph pb={7} textAlign="center">
          <Caption mt={5}>{translation.name}</Caption>
          <Caption mt={3}>{translation.addressLine1}</Caption>
          <Caption mt={3}>{translation.addressLine2}</Caption>
          <Caption mt={3}>{translation.kvkNumber}</Caption>
          <Caption mt={3}>{translation.btwNumber}</Caption>
        </Paragraph>
      )}
    </Wrapper>
  );
};

export default FooterMobile;
