import { useAuthentication } from '../../hooks/useAuthentication';

import { AUTHENTICATION_STATUS } from '../../enums';

const SignedIn = ({ children }) => {
  const { authenticationStatus } = useAuthentication();

  if (authenticationStatus === AUTHENTICATION_STATUS.AUTHENTICATED) {
    return children;
  }

  return null;
};

export default SignedIn;
