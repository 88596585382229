const base = {
  [LANG.NL]: {
    checkout10: {
      id: 'bestelling',
      label: 'Bestelling',
    },
    checkout20: {
      id: 'betalen',
      label: 'Betalen',
    },
    checkout30: {
      id: 'bevestiging',
      label: 'Bevestiging',
    },
    settlement20: {
      id: 'betaling',
      label: 'Betaling',
    },
    settlement30: {
      id: 'bevestiging',
      label: 'Bevestiging',
    },
    belgium: 'België',
    netherlands: 'Nederland',
    germany: 'Duitsland',
    luxembourg: 'Luxemburg',
  },
  [LANG.DE]: {
    checkout10: {
      id: 'bestellung',
      label: 'Bestellung',
    },
    checkout20: {
      id: 'zahlungsmethode',
      label: 'Zahlungsmethode',
    },
    checkout30: {
      id: 'bestatigung',
      label: 'Bestätigung',
    },
    settlement20: {
      id: 'zahlungsmethode',
      label: 'Zahlungsmethode',
    },
    settlement30: {
      id: 'bestatigung',
      label: 'Bestätigung',
    },
    belgium: 'Belgien',
    netherlands: 'Die Niederlande',
    germany: 'Deutschland',
    luxembourg: 'Luxemburg',
  },
  [LANG.EN]: {
    checkout10: {
      id: 'order',
      label: 'Order',
    },
    checkout20: {
      id: 'payment',
      label: 'Payment',
    },
    checkout30: {
      id: 'confirmation',
      label: 'Confirmation',
    },
    settlement20: {
      id: 'payment',
      label: 'Payment',
    },
    settlement30: {
      id: 'confirmation',
      label: 'Confirmation',
    },
    belgium: 'Belgium',
    netherlands: 'The Netherlands',
    germany: 'Germany',
    luxembourg: 'Luxembourg',
  },
  [LANG.FR]: {
    checkout10: {
      id: 'commande',
      label: 'Commande',
    },
    checkout20: {
      id: 'paiement',
      label: 'Paiement',
    },
    checkout30: {
      id: 'confirmation',
      label: 'Confirmation',
    },
    settlement20: {
      id: 'paiement',
      label: 'Paiement',
    },
    settlement30: {
      id: 'confirmation',
      label: 'Confirmation',
    },
    belgium: 'Belgique',
    netherlands: 'Pays-Bas',
    germany: 'Allemagne',
    luxembourg: 'Luxembourg',
  },
  [LANG.PT]: {
    checkout10: {
      id: 'order',
      label: 'Subscrição',
    },
    checkout20: {
      id: 'payment',
      label: 'Pagamento',
    },
    checkout30: {
      id: 'confirmation',
      label: 'Confirmação',
    },
    settlement20: {
      id: 'payment',
      label: 'Pagamento',
    },
    settlement30: {
      id: 'confirmation',
      label: 'Confirmação',
    },
    belgium: 'Belgium',
    netherlands: 'The Netherlands',
    germany: 'Germany',
    luxembourg: 'Luxembourg',
  },
};

export default base;
