import uniqBy from 'lodash.uniqby';
import uniq from 'lodash.uniq';

export default items => {
  // Get all features and add the corresponding item id to the object.
  // Then flatten the array of arrays and combine all data into a single array
  const allItemFeaturesWithItemId = items
    .map(item =>
      item.features.map(feature => {
        const itemId = [item.id];
        return { ...feature, itemId };
      }),
    )
    .flat()
    .map(featureArray => featureArray);

  // Filter out all duplicate features. This is neccesarry
  // because else the same feature will be rendered the number of items.
  const uniqueFeatures = uniqBy(allItemFeaturesWithItemId, 'id');

  // Filtering the features creates the problem that the remaining
  // features only have the itemId of a single item. Therefore is is
  // neccesarry to check which uniqueFeature is used by which item by checken
  //  uniqueFeature id against the id of the allItemFeaturesWithItemId array.
  const itemIdArray = uniqueFeatures.map(uniqueFeature => {
    const featuresWithCompleteItemIds = allItemFeaturesWithItemId.map(
      feature => {
        if (feature.id === uniqueFeature.id) {
          return [...uniqueFeature.itemId, ...feature.itemId];
        }
        return null;
      },
    );
    // If there is no match in featuresWithCompleteItemIds it will return null.
    // We have to filter out null to get the values we want.
    // This will leave an array with itemIds but it might have the
    // itemId of the the feature from uniqueFeatures multiple times as
    // we take that feature and add the other itemId to it when
    // feature.id === uniqueFeature.id is true in the code above.
    // Therefore we have to remove duplicate itemIds.
    return uniq(
      featuresWithCompleteItemIds
        .filter(
          featureWithCompleteItemIds => featureWithCompleteItemIds !== null,
        )
        .flat(),
    );
  });

  // Assign the correct itemId array to the uniqueFeature.
  return uniqueFeatures
    .map((uniqueFeature, index) => ({
      ...uniqueFeature,
      itemId: itemIdArray[index],
    }))
    .sort((a, b) => a.sequenceIndex - b.sequenceIndex);
};
