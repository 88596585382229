const base = {
  [LANG.NL]: {
    contactCustomerService: 'Contacteer klantenservice',
  },
  [LANG.DE]: {
    contactCustomerService: 'Kundendienst kontaktieren',
  },
  [LANG.EN]: {
    contactCustomerService: 'Contact customer service',
  },
  [LANG.FR]: {
    contactCustomerService: 'Contacter le service clientèle',
  },
  [LANG.PT]: {
    contactCustomerService: 'Contactar o serviço ao cliente',
  },
};

export default base;
