import language from './language';

export const getCheckout10 = lang => language[lang].checkout10;
export const getCheckout20 = lang => language[lang].checkout20;
export const getCheckout30 = lang => language[lang].checkout30;

export const getSettlement20 = lang => language[lang].settlement20;
export const getSettlement30 = lang => language[lang].settlement30;

export const PICKUP_TYPES = {
  HOME: 'home',
  STORE: 'store',
};

export const TRACKING_EVENTS = {
  CLOSE: 'close',
  OPEN: 'open',
  MODIFY: 'modify',
};

export const VALIDATIONS = Object.freeze({
  addressAreaValidation: 'addressAreaValidation',
  boolValidation: 'boolValidation',
  choiceValidation: 'choiceValidation',
  dateValidation: 'dateValidation',
  emailValidation: 'emailValidation',
  fileValidation: 'fileValidation',
  invoiceValidation: 'invoiceValidation',
  loginValidation: 'loginValidation',
  paymentValidation: 'paymentValidation',
  preconditionValidation: 'preconditionValidation',
  textFieldValidation: 'textFieldValidation',
  voucherValidation: 'voucherValidation',
});
