const goHome = (history, state = {}, options = {}) => {
  switch (true) {
    case options.isCheckout30:
      window.location.href = window.location.origin;
      break;
    default:
      history.push('/', state);
      break;
  }
};

export default goHome;
