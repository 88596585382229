import AbsoluteLabel from './AbsoluteLabel';
import InlineLabel from './InlineLabel';

const Label = ({ type, text }) =>
  type === 'absolute' ? (
    <AbsoluteLabel text={text} />
  ) : (
    <InlineLabel text={text} />
  );

export default Label;
