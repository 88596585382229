import {
  space1,
  space3,
  space4,
  space5,
  space9,
  colorRed30,
  colorGrey50,
  colorGrey90,
  colorRedBase,
  borderRadius2,
  colorWhiteBase,
  colorBlackBase,
  colorPrimary30,
  fontSizeMedium,
  lineHeightSmall,
  fontFamilySystem,
  fontWeightMedium,
  colorPrimaryBase,
} from '@mediahuis/chameleon-theme-wl';
import { createGlobalStyle } from 'styled-components';

export const DIALOG_WIDTH = '530px';

export const GlobalStyle = createGlobalStyle`
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    background-color: ${colorWhiteBase};
    overflow-y: scroll;
  }

  #root {
    font-family: ${fontFamilySystem};
  }

  .ant-tooltip-content {
    display: none;
  }

  #didomi-host li[title],
  #didomi-host span[title] {
    cursor: inherit !important;
    border-bottom: 0 !important;
  }

  #didomi-host h4 {
    font-size: 1.28571rem;
  }

  @keyframes fadeInOverlay {
    from {
      background-color: rgba(0, 0, 0, 0);
    }

    to {
      background-color: rgba(0, 0, 0, 0.75);
    }
  }

  @keyframes fadeOutOverlay {
    from {
      background-color: rgba(0, 0, 0, 75);
      opacity: 1;
    }

    to {
      background-color: rgba(0, 0, 0, 0);
      opacity: 0;
    }
  }

  @keyframes fadeIn {
    from {
      bottom: -100vh;
    }

    to {
      bottom: 0;
    }
  }

  @keyframes fadeOut {
    from {
      bottom: 0;
      opacity: 1;
    }

    to {
      bottom: -100vh;
      opacity: 0;
    }
  }
  
  .pdfViewer {
    max-width: 100%;
    height: auto !important;
  }

  #boolean-message {
    margin-left: -36px;
  }

  /* react-phone-number-input */
  
  .react-phone-number-input:focus-within {
    box-shadow: 0 0 0 3px #BDCBD1;
    border-radius: 4px;
    outline: 0;
  }

  .react-phone-number-input input {
    border: solid 1px #7E888B;
    border-radius: 0 4px 4px 0;
    color: inherit;
    font-family: ${fontFamilySystem};
    font-size: 16px;
    padding: 12px 16px;
    width: 100%;
    height: 48px;
  }
  
  .react-phone-number-input input:focus {
    border-color: #7E888B;
    outline: 0;
  }
  
  .react-phone-number-input.error input {
    border: solid 1px #e92326;
  }

  .PhoneInputCountry {
    margin: 0;
    padding: 0 10px;
    border: solid 1px #7E888B;
    border-radius: 4px 0 0 4px;
    border-right: 0;
    background: #f5f5f5;

    &:hover {
      background: transparent;
    }
  }

  .PhoneInputCountryIcon,
  .PhoneInputCountryIcon--border {
    box-shadow: none !important;
  }

  .PhoneInputCountrySelectArrow {
    opacity: 1;
    width: 0.3em !important;
    height: 0.3em !important;
    border-bottom: 1px solid black !important;
    border-right: 1px solid black !important;
  }

  /* Mollie + Secure Fields */

  .mollie-component {
    background-color: ${colorWhiteBase};
    border-radius: ${borderRadius2};
    border: solid 1px ${colorGrey50};
    color: ${colorGrey90};
    font-family: ${fontFamilySystem};
    font-size: ${fontSizeMedium};
    padding: ${space4} ${space5};
    width: 100%;
    height: ${space9};

    &:hover {
      border-color: ${colorPrimaryBase};
    }

    &.has-focus {
      box-shadow: 0 0 0 2px ${colorPrimary30};
      outline: 0;
    }

    &.is-invalid {
      border-color: ${colorRedBase};

      &.has-focus {
        box-shadow: 0 0 0 2px ${colorRed30};
      }
    }
  }

  .mollie-label, .sf-label {
    display: inline-block;
    font-family: ${fontFamilySystem};
    font-size: ${fontSizeMedium};
    font-weight: ${fontWeightMedium};
    color: ${colorBlackBase};
    margin-right: ${space1};
    margin-bottom: ${space3};
    line-height: ${lineHeightSmall};
  }

  .sf-container {
    height: ${space9};
  }
`;
