import getQueryParamPrefix from './getQueryParamPrefix';

export default ({ url, params }) => {
  const searchParams = params && new URLSearchParams(params);
  if (searchParams) {
    for (const [key, value] of searchParams) {
      url += `${getQueryParamPrefix(url)}${key}=${value}`;
    }
  }
  return url;
};
