import apiCall from './apiCall';

const getConfiguration = ({ onSuccess, onError }) => {
  return apiCall(
    `https://mh-subscriber-settings.s3.eu-west-1.amazonaws.com/public/${MH_ENV}/aboshop/configuration.json`,
    {
      method: 'GET',
      onSuccess: response => {
        if (onSuccess) {
          return onSuccess(response);
        }
        return response;
      },
      onError: error => {
        if (onError) {
          return onError(error);
        }
        return error;
      },
    },
  );
};

export default getConfiguration;
