import { useEffect, useState } from 'react';
import { colorRed50 } from '@mediahuis/chameleon-theme-wl';
import { Box, Text, Paragraph } from '@mediahuis/chameleon-react';
import 'react-phone-number-input/style.css';
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from 'react-phone-number-input';

const PhoneNumberTextField = ({
  defaultCountry,
  errorMessage,
  id,
  label,
  onBlur,
}) => {
  const [isValid, setIsValid] = useState(false);
  const [value, setValue] = useState();

  useEffect(() => {
    const isValidValue = !!isValidPhoneNumber(value ? value : '');

    if (isValidValue) {
      setIsValid(true);
      setValue(formatPhoneNumberIntl(value));
    } else {
      setIsValid(false);
    }
  }, [value]);

  return (
    <Box mb={5}>
      {label && <Paragraph mb={3}>{label}</Paragraph>}
      <PhoneInput
        className={`react-phone-number-input ${
          !isValid && errorMessage ? 'error' : ''
        }`}
        countryCallingCodeEditable={false}
        defaultCountry={defaultCountry}
        id={id}
        international
        value={value}
        onBlur={onBlur}
        onChange={setValue}
      />
      {!isValid && errorMessage && (
        <Text size="Caption1" as="div" style={{ color: colorRed50 }} mt={3}>
          {errorMessage}
        </Text>
      )}
    </Box>
  );
};

export default PhoneNumberTextField;
