import { useEffect, useState } from 'react';
import axios from 'axios';
import { getI18n } from 'react-i18next';

export default (cityOrPostalCode, country) => {
  const [matchedCities, setMatchedCities] = useState([]);
  const { language } = getI18n();

  useEffect(() => {
    if (cityOrPostalCode) {
      axios({
        method: 'post',
        url: `${API_ADDRESS_SERVICE}/Address/AutoComplete/Cities/${country}`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          Key: cityOrPostalCode,
          Language: language,
        },
      })
        .then(res => setMatchedCities(res.data.Cities))
        .catch(err => err);
    }
  }, [cityOrPostalCode, country, language]);

  return matchedCities;
};
