const getCookie = name => {
  // eslint-disable-next-line no-restricted-globals
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  if (match) {
    return match[2];
  }
  return null;
};

export default getCookie;
