import { Box, Flex, TextField } from '@mediahuis/chameleon-react';
import { Fragment } from 'react';
import { getI18n } from 'react-i18next';
import PostalCodeAutoComplete from './autocomplete/PostalCodeAutoComplete';
import StreetAutoComplete from './autocomplete/StreetAutoComplete';
import HouseNumberTextField from './components/HouseNumberTextField';
import language from './language';
import useCity from './useCity';
import useStreets from './useStreets';

const DefaultAddressFields = ({
  onChange,
  values,
  clearFields,
  errors = {},
  validateOnBlur,
  clearError,
  disabled = false,
}) => {
  const { language: selectedLanguage } = getI18n();
  const translation = language[selectedLanguage];

  const { postalCode, city, street, houseNumber, box, country } = values;

  const suggestionItems = useCity(postalCode, country);
  const streets = useStreets(street, postalCode, country);
  const formFields = ['houseNumber', 'box', 'street', 'city', 'box'];

  const resetFields = value => {
    if (value !== postalCode && postalCode !== '') {
      // emptying array by removing index 0
      // so all street suggestions get removed
      streets.splice(0, 1);
      clearFields(formFields);
    }
  };

  return (
    <Fragment>
      <Box pb={4}>
        <Box pb={4}>
          <PostalCodeAutoComplete
            onChange={selection => {
              if (selection && selection.PostalCode && selection.Name) {
                onChange('postalCode', selection.PostalCode);
                onChange('city', selection.Name);
                const cityEvent = { target: { id: 'city' } };
                clearError(cityEvent);
              }
            }}
            itemToString={item => (item ? item.PostalCode : '')}
            onInputValueChange={value => {
              resetFields(value);
              onChange('postalCode', value);
            }}
            postalCode={postalCode}
            label={translation.postalCode}
            items={suggestionItems}
            errors={errors}
            streets={streets}
            validateOnBlur={validateOnBlur}
            clearError={clearError}
            autoComplete="new-postalCode"
            disabled={disabled}
          />
        </Box>
        <Box>
          <TextField
            autoComplete="new-city"
            data-testid="input-city"
            disabled
            id="city"
            label={translation.city}
            maxlength={255}
            value={city}
            onChange={event => onChange(event.target.id, event.target.value)}
          />
        </Box>
      </Box>
      <Box pb={4}>
        <Flex>
          <Box pb={4} flex={3}>
            <StreetAutoComplete
              onChange={selection => {
                if (selection && selection.Name) {
                  onChange('street', selection.Name);
                }
              }}
              itemToString={cStreet => (cStreet ? cStreet.Name : '')}
              onInputValueChange={value => {
                onChange('street', value);
              }}
              disabled={disabled || !postalCode}
              label={translation.street}
              street={street}
              streets={streets}
              errors={errors}
              validateOnBlur={validateOnBlur}
              clearError={clearError}
              autoComplete="new-street"
            />
          </Box>
        </Flex>
        <Box pb={4} flex={2}>
          <Flex>
            <Box pb={2} mr={MH_GROUP === 'LUX' ? 0 : 3} flex={1}>
              <HouseNumberTextField
                validateOnBlur={validateOnBlur}
                clearError={clearError}
                houseNumber={houseNumber}
                errors={errors}
                disabled={disabled || !street}
                onChange={onChange}
              />
            </Box>
            {MH_GROUP !== 'LUX' && (
              <Box flex={1}>
                <TextField
                  autoComplete="new-box"
                  data-testid="input-box"
                  disabled={disabled || !street}
                  id="box"
                  label={translation.box}
                  maxlength={50}
                  optionalLabel={translation.optional}
                  value={box || ''}
                  onBlur={validateOnBlur}
                  onChange={event =>
                    onChange(event.target.id, event.target.value)
                  }
                />
              </Box>
            )}
          </Flex>
        </Box>
      </Box>
    </Fragment>
  );
};

export default DefaultAddressFields;
