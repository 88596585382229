import DefaultAddressFields from './DefaultAddressFields';
import DutchAddressFields from './DutchAddressFields';
import GermanAddressFields from './GermanAddressFields';
import OtherAddressFields from './OtherAddressFields';

const AddressFields = ({ dutchStreets, ...props }) => {
  switch (props.country) {
    case 'DE':
      return <GermanAddressFields {...props} />;
    case 'NL':
      return <DutchAddressFields streets={dutchStreets} {...props} />;
    case 'BE':
    case 'LU':
      return <DefaultAddressFields {...props} />;
    default:
      return <OtherAddressFields {...props} />;
  }
};

export default AddressFields;
