const base = {
  [LANG.NL]: {
    imageAlt: 'uploaded',
    selectFile: `Zet hier je bestand neer of klik om toe te voegen`,
    fileExtensions: '(JPG, JPEG, PNG, GIF of PDF - max. 15MB)',
    lengthError: 'Het bestand mag maximaal 15MB zijn.',
    fileTypeError:
      'Ongeldig bestandstype. Wij ondersteunen JPG, JPEG, PNG, GIF en PDF.',
  },
  [LANG.DE]: {
    imageAlt: 'uploaded',
    selectFile: `Ihre Daten hier ablegen oder hier klicken, um sie hinzuzufügen`,
    fileExtensions: '(JPG, JPEG, PNG, GIF oder PDF - max. 15MB)',
    lengthError: 'Die Daten darfen maximal 15MB groß sein.',
    fileTypeError:
      'Ungültiger Datentyp. Wir unterstützen JPG, JPEG, PNG, GIF und PDF.',
  },
  [LANG.EN]: {
    imageAlt: 'uploaded',
    selectFile: 'Drag your file here, or click to upload',
    fileExtensions: '(JPG, JPEG, PNG, GIF or PDF - max. 15MB)',
    lengthError: 'The filesize may not exceed 15MB.',
    fileTypeError:
      'Invalid filetype. We only support JPG, JPEG, PNG, GIF and PDF.',
  },
  [LANG.FR]: {
    imageAlt: 'téléchargé',
    selectFile: 'Déposez votre fichier ici, ou cliquez pour ajouter',
    fileExtensions: '(JPG, JPEG, PNG, GIF ou PDF - max. 15MB)',
    lengthError: 'La taille du fichier ne peut pas dépasser 15MB.',
    fileTypeError:
      'Type de fichier invalide. Nous ne supportons que JPG, JPEG, PNG, GIF et PDF.',
  },
  [LANG.PT]: {
    imageAlt: 'descarregado',
    selectFile: 'Arraste o seu ficheiro para aqui, ou clique para adicionar',
    fileExtensions: '(JPG, JPEG, PNG, GIF, SVG ou PDF - máx. 15MB)',
    lengthError: 'O tamanho dos ficheiros não pode exceder 15MB',
    fileTypeError:
      'Tipo de ficheiro inválido. Aceitamos JPG, JPEG, PNG, GIF e PDF.',
  },
};

export default base;
