import { useEffect, useState } from 'react';
import axios from 'axios';
import { getI18n } from 'react-i18next';

export default (streetName, postalCode, country) => {
  const [matchedStreets, setMatchedStreets] = useState([]);
  const { language } = getI18n();

  useEffect(() => {
    if (streetName && postalCode) {
      axios({
        method: 'post',
        url: `${API_ADDRESS_SERVICE}/Address/AutoComplete/Streets${
          country ? `/${country}` : ''
        }`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          Key: streetName,
          PostalCode: postalCode,
          Language: language,
        },
      })
        .then(res => setMatchedStreets(res.data.Streets))
        .catch(err => err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, postalCode, streetName]);

  return matchedStreets;
};
