import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { hydrate, render } from 'react-dom';
import '@mediahuis/chameleon-theme-wl/lib/web/fonts.css';
import App from './App';
import initializeTracker from './utils/tracking/initialiseTracker';
import { setDummyTracker } from './utils/tracking/tracker';

// Initialise tracker fallback
window.mht = [];

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  initializeTracker();
  hydrate(<App />, rootElement);
} else {
  if (MH_ENV === 'test' || MH_ENV === 'test2') {
    initializeTracker();
  } else {
    setDummyTracker();
  }

  render(
    <App staticRender={MH_ENV !== 'test' && MH_ENV !== 'test2'} />,
    rootElement,
  );
}
