import Downshift from 'downshift';
import { TextField } from '@mediahuis/chameleon-react';
import {
  colorGrey30,
  colorWhiteBase,
  fontWeightBold,
  fontWeightRegular,
  fontFamilySystem,
} from '@mediahuis/chameleon-theme-wl';
import SuggestionWrapper from '../../SuggestionWrapper';
import SuggestionItem from '../../SuggestionItem';
import FormGroup from '../../FormGroup';
import noop from '../../../utils/noop';

const PostalCodeAutoComplete = ({
  autoComplete = '',
  clearError = noop,
  disabled = false,
  errors = {},
  label,
  items,
  itemToString,
  postalCode,
  validateOnBlur = noop,
  onChange,
  onInputValueChange,
}) => (
  <Downshift
    onChange={onChange}
    itemToString={itemToString}
    onInputValueChange={onInputValueChange}
    initialSelectedItem={postalCode}
    stateReducer={(state, changes) => {
      // We need to check if the changes made
      // are of a valid type or undefined to know
      // how to update the state which uses this component
      if (changes.inputValue === undefined) {
        return { ...changes, inputValue: postalCode };
      }
      return changes;
    }}
  >
    {({
      getInputProps,
      getItemProps,
      getMenuProps,
      getRootProps,
      isOpen,
      inputValue,
      highlightedIndex,
      selectedItem,
      clearSelection,
    }) => (
      <FormGroup {...getRootProps({ refKey: 'innerRef' })}>
        <TextField
          {...getInputProps({
            onChange: e => {
              if (e.target.value !== postalCode) {
                clearSelection();
              }
            },
            onBlur: e => {
              // Event does not work as expected with Downshift,
              // we pass a custom object with eiter an empty
              // string or selected object.
              const validPostalCode = items.find(
                item => item.PostalCode === e.target.value,
              );
              const downshiftValues = {
                target: {
                  id: 'postalCode',
                  value: validPostalCode ? postalCode : '',
                },
              };
              validateOnBlur(downshiftValues);
            },
          })}
          autoComplete={autoComplete}
          data-testid="postalCode-autocomplete"
          disabled={disabled}
          error={!!errors.postalCode}
          id="postalCode"
          label={label}
          maxlength={50}
          message={errors.postalCode}
          value={postalCode}
          onFocus={clearError}
        />
        <SuggestionWrapper {...getMenuProps()}>
          {isOpen &&
            items
              .filter(
                item =>
                  !inputValue ||
                  item.PostalCode.toLowerCase().includes(
                    // Remove possible spacing of postalCode.
                    inputValue.replace(/\s+/g, '').toLowerCase(),
                  ),
              )
              .map((item, index) => (
                <SuggestionItem
                  key={item}
                  {...getItemProps({
                    key: index,
                    index,
                    item,
                    style: {
                      backgroundColor:
                        highlightedIndex === index
                          ? colorGrey30
                          : colorWhiteBase,
                      fontWeight:
                        selectedItem === item
                          ? fontWeightBold
                          : fontWeightRegular,
                      fontFamily: fontFamilySystem,
                    },
                  })}
                >
                  {item.Name} ({item.PostalCode})
                </SuggestionItem>
              ))}
        </SuggestionWrapper>
      </FormGroup>
    )}
  </Downshift>
);
export default PostalCodeAutoComplete;
