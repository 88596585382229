const updateGlobalStateWithUserData = ({
  user,
  setGlobalState,
  setInitiallyHasFirstName,
  setInitiallyHasName,
}) => {
  setInitiallyHasFirstName(Boolean(user?.firstName));
  setInitiallyHasName(Boolean(user?.name));

  /* Make sure that value is set to empty string when null or undefined because it breaks certain input behaviours that we need */
  setGlobalState({
    user,
    address: {
      personal: {
        firstName: user.firstName || '',
        name: user.name || '',
        country: user.country || '',
        postalCode: user.zipCode || '',
        city: user.city || '',
        street: user.street || '',
        houseNumber: user.houseNumber || '',
        box: user.box || '',
      },
      pickup: {},
    },
    invoice: {
      checked: false,
      vatNumber: '',
      companyName: '',
      firstName: user.firstName || '',
      name: user.name || '',
      country: user.country || '',
      postalCode: user.zipCode || '',
      city: user.city || '',
      street: user.street || '',
      houseNumber: user.houseNumber || '',
      box: user.box || '',
    },
    date: {
      checked: true,
      value: null,
    },
  });
};

export default updateGlobalStateWithUserData;
