import Wrapper from './Wrapper';
import PageLoader from './PageLoader';

// no staticRender on test env && local env
const StaticRender = ({ staticRender, children }) =>
  staticRender ? (
    <Wrapper>
      <PageLoader />
      <div style={{ display: 'none' }}>{children}</div>
    </Wrapper>
  ) : (
    <Wrapper>{children}</Wrapper>
  );

export default StaticRender;
