import { AuthLuxProvider } from '@subscriber/authentication-lux';
import { CiamProvider } from '@subscriber/ciam';

const AuthenticationProvider = ({ children }) => {
  return (
    <AuthLuxProvider
      autoAuthentication={MH_GROUP === 'LUX'}
      brand={MH_BRAND}
      environment={MH_ENV}
    >
      <CiamProvider
        autoAuthentication={MH_GROUP === 'BE' || MH_GROUP === 'DL'}
        brand={MH_BRAND}
        brandGroup={MH_GROUP === 'DL' ? 'mhli' : 'mhbe'}
        clientId={CIAM_CLIENT_ID}
        environment={MH_ENV === 'test2' ? 'test' : MH_ENV}
      >
        {children}
      </CiamProvider>
    </AuthLuxProvider>
  );
};

export default AuthenticationProvider;
