import { useAuthenticationLux as useAuthLux } from '@subscriber/authentication-lux';
import { useCiam } from '@subscriber/ciam';

import { AUTHENTICATION_STATUS } from '../enums';
import { mapCiamUserFields, mapLuxUserFields } from '../utils';

export const useAuthentication = () => {
  const {
    authenticationStatus: authLuxStatus,
    userInfo: authLuxUserInfo,
    getUserInfo: getUserInfoLux,
    redirectToSignOut: redirectToSignOutLux,
  } = useAuthLux();
  const {
    authenticationStatus: ciamAuthStatus,
    userInfo: ciamUserInfo,
    getUserInfo: getUserInfoCiam,
    redirectToSignOut: redirectToSignOutCiam,
  } = useCiam();

  function getUserInfo() {
    if (MH_GROUP === 'BE' || MH_GROUP === 'DL') {
      return getUserInfoCiam().then(idToken =>
        idToken ? mapCiamUserFields(idToken) : null,
      );
    }
    if (MH_GROUP === 'LUX') {
      return getUserInfoLux().then(userInfoLux =>
        userInfoLux ? mapLuxUserFields(userInfoLux) : null,
      );
    }

    return Promise.reject();
  }

  function redirectToSignOut(redirectUrl) {
    if (MH_GROUP === 'BE' || MH_GROUP === 'DL') {
      redirectToSignOutCiam({
        returnTo: redirectUrl,
      });

      if (MH_GROUP === 'LUX') {
        redirectToSignOutLux(redirectUrl);
      }
    }
  }

  function resolveAuthenticationStatus() {
    if (MH_GROUP === 'BE' || MH_GROUP === 'DL') {
      return ciamAuthStatus;
    }
    if (MH_GROUP === 'LUX') {
      return authLuxStatus;
    }

    return AUTHENTICATION_STATUS.UNKNOWN;
  }

  function resolveUserInfo() {
    if (MH_GROUP === 'BE' || MH_GROUP === 'DL') {
      if (ciamUserInfo) {
        return mapCiamUserFields(ciamUserInfo);
      }
    }
    if (MH_GROUP === 'LUX' && authLuxUserInfo) {
      return mapLuxUserFields(authLuxUserInfo);
    }

    return null;
  }

  return {
    authenticationStatus: resolveAuthenticationStatus(),
    ciamToken: ciamUserInfo?.tokens.id || undefined,
    userInfo: resolveUserInfo(),
    getUserInfo,
    redirectToSignOut,
  };
};
