import { useEffect } from 'react';

const WindowProvider = ({ children }) => {
  useEffect(() => {
    window.MH = window.MH || {};

    window.MH.getAccessToken = () => window.CIAM.getAccessToken(CIAM_CLIENT_ID);
    window.MH.getIdToken = () => window.CIAM.getIdToken(CIAM_CLIENT_ID);
    window.MH.getFullUserProfile = () =>
      window.CIAM.getFullUserProfile(CIAM_CLIENT_ID);
  }, []);

  return children;
};

export default WindowProvider;
