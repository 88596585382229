import { Box } from '@mediahuis/chameleon-react';

const ContentCard = ({ children }) => (
  <Box
    p={5}
    borderColor="grey10"
    borderWidth={2}
    borderRadius={2}
    borderStyle="solid"
  >
    {children}
  </Box>
);

export default ContentCard;
