import { Fragment } from 'react';
import { getI18n } from 'react-i18next';
import { Box, Flex, TextField } from '@mediahuis/chameleon-react';
import HouseNumberTextField from './components/HouseNumberTextField';
import language from './language';

const GermanAddressFields = ({
  values,
  onChange,
  clearError,
  errors = {},
  validateOnBlur,
}) => {
  const { language: selectedLanguage } = getI18n();
  const translation = language[selectedLanguage];
  const { postalCode, city, street, houseNumber, box } = values;

  return (
    <Fragment>
      <Box pb={4}>
        <Box pb={4}>
          <TextField
            autoComplete="new-postalCode"
            data-testid="input-postalCode"
            error={!!errors.postalCode}
            id="postalCode"
            label={translation.postalCode}
            maxlength={50}
            message={errors.postalCode}
            value={postalCode}
            onBlur={validateOnBlur}
            onChange={event => onChange(event.target.id, event.target.value)}
            onFocus={clearError}
          />
        </Box>
        <Box>
          <TextField
            autoComplete="new-city"
            data-testid="input-city"
            error={!!errors.city}
            id="city"
            label={translation.city}
            maxlength={255}
            message={errors.city}
            value={city}
            onBlur={validateOnBlur}
            onChange={event => onChange(event.target.id, event.target.value)}
            onFocus={clearError}
          />
        </Box>
      </Box>
      <Box pb={4}>
        <Flex>
          <Box pb={4} flex={3}>
            <TextField
              autoComplete="new-street"
              data-testid="input-street"
              error={!!errors.street}
              id="street"
              label={translation.street}
              maxlength={255}
              message={errors.street}
              value={street}
              onBlur={validateOnBlur}
              onChange={event => onChange(event.target.id, event.target.value)}
              onFocus={clearError}
            />
          </Box>
        </Flex>
        <Box pb={4} flex={2}>
          <Flex>
            <Box pb={2} mr={3} flex={1}>
              <HouseNumberTextField
                validateOnBlur={validateOnBlur}
                clearError={clearError}
                houseNumber={houseNumber}
                errors={errors}
                disabled={!postalCode}
                onChange={onChange}
              />
            </Box>
            <Box flex={1}>
              <TextField
                autoComplete="new-box"
                data-testid="input-box"
                id="box"
                label={translation.box}
                maxlength={MH_GROUP === 'LUX' ? 5 : 50}
                optionalLabel={translation.optional}
                value={box || ''}
                onBlur={validateOnBlur}
                onChange={event =>
                  onChange(event.target.id, event.target.value)
                }
              />
            </Box>
          </Flex>
        </Box>
      </Box>
    </Fragment>
  );
};

export default GermanAddressFields;
