import { useEffect } from 'react';
import { Upload } from 'antd';
import { getI18n } from 'react-i18next';
import { Paragraph, Box, Heading } from '@mediahuis/chameleon-react';
import { InboxOutlined } from '@ant-design/icons';
import { useGlobalContext } from '~/context/global';
import { useCheckout10Context } from '~/context/checkout10';
import language from './language';

const FileUpload = ({ fileUpload, clearError, setErrors }) => {
  const { language: selectedLanguage } = getI18n();
  const translation = language[selectedLanguage];
  const { Dragger } = Upload;
  const { setGlobalState } = useGlobalContext();
  const { setMessage } = useCheckout10Context();

  const fileReset = () => {
    clearError('file');
    setGlobalState({
      uploads: {
        pdf: null,
        image: null,
        file: null,
      },
    });
  };

  const allowedFileTypes = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/gif',
    'application/pdf',
  ];

  useEffect(() => {
    fileReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFileUploadChange = data => {
    setMessage('');
    const file = data.file;
    if (file.originFileObj) {
      // removal event
      return;
    }
    if (file.isLt) {
      // file size is too small
      file.isLt = false;
      setErrors({ file: translation.lengthError });
      return;
    }

    clearError('file');
    if (file.type === 'application/pdf') {
      setGlobalState({
        uploads: {
          pdf: file.name,
          image: null,
          file,
        },
      });
    } else {
      setGlobalState({
        uploads: {
          pdf: null,
          image: file.name,
          file,
        },
      });
    }
  };

  const beforeUpload = file => {
    const isLt15M = file.size / 1024 / 1024 < 15;

    if (!isLt15M) {
      setErrors({ file: translation.lengthError });
      file.isLt = true;
      file.error = true;
      return file;
    }
    if (!allowedFileTypes.some(fileType => fileType === file.type)) {
      setErrors({ file: translation.fileTypeError });
      return file;
    }
    return false;
  };

  return (
    <Box mb={5}>
      <Box pb={3}>
        <Heading level={6}>{fileUpload?.description}</Heading>
      </Box>
      <Dragger
        name="file"
        multiple={false}
        maxCount={1}
        listType="picture"
        beforeUpload={beforeUpload}
        accept="image/jpg, image/jpeg, image/png, image/gif, application/pdf"
        response={false}
        onRemove={fileReset}
        onChange={onFileUploadChange}
      >
        <Paragraph className="ant-upload-drag-icon">
          <InboxOutlined />
        </Paragraph>
        <Paragraph className="ant-upload-text">
          {translation.selectFile}
        </Paragraph>
        <Paragraph className="ant-upload-hint">
          {translation.fileExtensions}
        </Paragraph>
      </Dragger>
    </Box>
  );
};

export default FileUpload;
