import { useEffect, useState } from 'react';
import axios from 'axios';
import { getI18n } from 'react-i18next';
import language from '../constants/language';

export const getCountries = () => {
  const { language: lang } = getI18n();
  const translation = language[lang];

  return {
    BE: {
      code: 'BE',
      name: translation.belgium,
      locale: 'nl_BE',
    },
    NL: {
      code: 'NL',
      name: translation.netherlands,
      locale: 'nl_NL',
    },
    DE: {
      code: 'DE',
      name: translation.germany,
      locale: 'de_DE',
    },
    LU: {
      code: 'LU',
      name: translation.luxembourg,
      locale: 'de_LU',
    },
  };
};

const getDefaultCountries = () => {
  const COUNTRIES = getCountries();
  switch (MH_GROUP) {
    case GROUPS.BE:
      return [COUNTRIES.BE, COUNTRIES.NL];
    case GROUPS.DL: {
      return [COUNTRIES.BE, COUNTRIES.NL, COUNTRIES.DE];
    }
    case GROUPS.LUX: {
      return [COUNTRIES.LU];
    }
    default:
      return [COUNTRIES.BE];
  }
};

export const getCountriesWithLanguage = ({ countries, onSuccess }) => {
  const { language: lang } = getI18n();

  axios({
    method: 'get',
    url: `${API_ADDRESS_SERVICE}/Countries/Language/${lang}`,
  })
    .then(res => {
      if (!countries) {
        return onSuccess(res.data);
      }
      /**
       * TODO if Address service API for country select should be updated with multiple language support as params.
       * If this service is ready replace filter logic by API logic.
       */
      return onSuccess(
        res.data.filter(
          resCountry => countries.indexOf(resCountry.IsoCode) > -1,
        ),
      );
    })
    .catch(() => onSuccess(getDefaultCountries()));
};

export const useCountryName = ({ countryCode }) => {
  const [countryName, setCountryName] = useState('');
  const { language: lang } = getI18n();

  useEffect(() => {
    countryCode &&
      axios({
        method: 'get',
        url: `${API_ADDRESS_SERVICE}/Countries/${countryCode.toUpperCase()}`,
        params: {
          LanguageCode: lang,
        },
      })
        .then(res => setCountryName(res.data.Name))
        .catch(error => error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode]);

  return countryName;
};
