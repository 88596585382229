import apiCall from './apiCall';

const getOfferFormula = ({ offerId, formulaId, onSuccess, onError }) =>
  apiCall(`${OFFER_RENEWAL_SERVICE}/OfferRenewals`, {
    method: 'GET',
    params: { offerId, formulaId },
    onSuccess: response => {
      if (onSuccess) {
        return onSuccess(response);
      }
      return response;
    },
    onError: error => {
      if (onError) {
        return onError(error);
      }
      throw error;
    },
  });

export default getOfferFormula;
