import apiCall from './apiCall';
import getOffer from './getOffer';

const getRenewalOffer = ({ accountId, onError, onSuccess }) =>
  apiCall(`${OFFER_RENEWAL_SERVICE}/OfferRenewals/${accountId}`, {
    method: 'GET',
    onSuccess: response => {
      return getOffer({ id: response, onError, onSuccess });
    },
    onError: error => {
      if (onError) {
        return onError(error);
      }
      return null;
    },
  });

export default getRenewalOffer;
