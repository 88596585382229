import { Authentication as LuxAuthentication } from '@subscriber/authentication-lux';
import {
  CIAM_AUTHENTICATION_STATUS,
  CIAM_IDENTITY_LEVEL,
  Authentication as CiamAuthentication,
  RedirectToSignIn,
  useCiam,
} from '@subscriber/ciam';

import { useCheckoutContext } from '~/context/checkout';
import PageLoader from '~/components/PageLoader';

const Authentication = ({ children }) => {
  const {
    authenticationStatus: ciamAuthStatus,
    userInfo: ciamUserInfo,
  } = useCiam();

  const { validations } = useCheckoutContext();

  const isCiamAuthenticated = MH_GROUP === 'BE' || MH_GROUP === 'DL';
  const isLuxAuthenticated = MH_GROUP === 'LUX';

  if (isCiamAuthenticated) {
    return (
      <CiamAuthentication fallback={<PageLoader />}>
        {ciamAuthStatus === CIAM_AUTHENTICATION_STATUS.AUTHENTICATED &&
        !ciamUserInfo?.accessToken[
          'https://mediahuis.com/identity-levels'
        ]?.includes(
          validations?.loginValidation?.identityLevel || CIAM_IDENTITY_LEVEL.MH5,
        ) ? (
          <RedirectToSignIn
            options={{
              loginIdentityLevel:
                validations?.loginValidation?.identityLevel ||
                CIAM_IDENTITY_LEVEL.MH5,
              returnTo: window.location.href,
              skipEmailVerification: '1',
            }}
          />
        ) : (
          children
        )}
      </CiamAuthentication>
    );
  }

  if (isLuxAuthenticated) {
    return (
      <LuxAuthentication fallback={<PageLoader />}>
        {children}
      </LuxAuthentication>
    );
  }

  return children;
};

export default Authentication;
