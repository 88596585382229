export const getAuthLuxUrl = () =>
  MH_ENV === ENVS.production
    ? 'https://user.wort.lu'
    : 'https://sp-sso-test.wort.lu';

export const mapCiamUserFields = userInfo => ({
  accountGuid: userInfo.idToken.sub,
  birthDate: userInfo.fullIdentity.demographicsPerson?.dateOfBirth,
  box: userInfo.fullIdentity.address?.houseNumberExtension,
  city: userInfo.fullIdentity.address?.city,
  country: userInfo.fullIdentity.address?.countryCode,
  emailAddress: userInfo.idToken.email,
  firstName:
    userInfo.fullIdentity.namesPerson?.firstName || userInfo.idToken.given_name,
  gender: userInfo.idToken.gender?.type,
  houseNumber: userInfo.fullIdentity.address?.houseNumber,
  name:
    userInfo.fullIdentity.namesPerson?.lastName || userInfo.idToken.family_name,
  phoneNumber: userInfo.fullIdentity.contactpointsPhone?.phone,
  street: userInfo.fullIdentity.address?.street,
  zipCode: userInfo.fullIdentity.address?.postalCode,
});

export const mapLuxUserFields = userInfo => ({
  accountGuid: userInfo.user.user.id,
  country: 'LU',
  emailAddress: userInfo.user.user.email,
  firstName: userInfo.user.user.contactDetails.firstName,
  gender: userInfo.user.user.contactDetails.gender,
  name: userInfo.user.user.contactDetails.lastName,
});

export const updateAccountInfo = ({ onSuccess, onError, user }) =>
  fetch(`${ACCOUNT_MANAGEMENT}/v2/Account/${user?.accountGuid}`, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  })
    .then(response => response.json())
    .then(response => onSuccess(response) ?? response)
    .catch(error => onError(error) ?? error);
