import { Fragment } from 'react';
import styled from 'styled-components';
import {
  shadow2,
  colorWhiteBase,
  overlayFill,
} from '@mediahuis/chameleon-theme-wl';

const StickyWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  box-shadow: ${shadow2};
  background-color: ${colorWhiteBase};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const Overlay = styled.div`
  background-color: ${overlayFill};
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 5;
`;

const StickyModal = ({ children, isOpen, closeModal }) => (
  <Fragment>
    {isOpen && <Overlay onClick={() => closeModal()} />}
    <StickyWrapper>{children}</StickyWrapper>
  </Fragment>
);

export default StickyModal;
