/* eslint-disable react/jsx-key */

const BANNERS = {
  INFO: 'info',
  ERROR: 'error',
};

const PAYMENTS = {
  [LANG.NL]: {
    FAILED:
      'Er ging iets mis met de afhandeling van de betaling. Probeer opnieuw of kies een andere betaalwijze.',
    CANCELED: 'Probeer opnieuw of kies een andere betaalwijze.',
    EXPIRED:
      'Uw sessie is verlopen, probeer opnieuw of kies een andere betaalwijze.',
  },
  [LANG.DE]: {
    FAILED:
      'Bei der Zahlungsabwicklung ist etwas schief gelaufen. Bitte versuchen Sie es erneut oder wählen Sie eine andere Zahlungsmethode.',
    CANCELED:
      'Versuchen Sie es erneut oder wählen Sie eine andere Zahlungsmethode.',
    EXPIRED:
      'Ihre Sitzung ist abgelaufen. Bitte versuchen Sie es erneut oder wählen Sie eine andere Zahlungsmethode.',
  },
  [LANG.EN]: {
    FAILED:
      'Something went wrong while making your payment. Please try again or choose another method.',
    CANCELED: 'Please try again or choose another method.',
    EXPIRED:
      'Your session has expired, please try again or choose another method.',
  },
  [LANG.FR]: {
    FAILED:
      "Une erreur s'est produite lors de votre paiement. Veuillez réessayer ou choisir une autre méthode.",
    CANCELED: 'Veuillez réessayer ou choisir une autre méthode.',
    EXPIRED:
      'Votre session a expiré, veuillez réessayer ou choisir une autre méthode.',
  },
  [LANG.PT]: {
    FAILED:
      'Algo correu mal com o seu pagamento. Por favor, tente novamente ou escolha outro método de pagamento',
    CANCELED: 'Please try again or choose another method.',
    EXPIRED:
      'A sua sessão expirou, por favor tente novamente ou escolha outro método de pagamento',
  },
};

const ERRORS = {
  [LANG.NL]: {
    CardHolder: 'Kaarthouder kan niet leeg zijn.',
    InvalidIban: 'Ongeldig IBAN bankrekeningnummer',
    FileUpload: `Er ging iets mis met de afhandeling van je bestand. Probeer opnieuw of kies een ander bestand.`,
  },
  [LANG.DE]: {
    CardHolder: 'Karteninhaber darf nicht leer sein.',
    InvalidIban: 'Ungültige IBAN-Bankkontonummer',
    FileUpload:
      'Beim Bearbeiten Ihrer Datei ist etwas schief gelaufen. Versuchen Sie es erneut oder wählen Sie eine andere Datei aus.',
  },
  [LANG.EN]: {
    CardHolder: 'Card Holder cannot be empty.',
    InvalidIban: 'Invalid IBAN account number',
    FileUpload:
      'Something went wrong while handling your file. Please try again or choose another file.',
  },
  [LANG.FR]: {
    CardHolder: 'Le titulaire de la carte ne peut pas être vide.',
    InvalidIban: 'Numéro de compte IBAN invalide',
    FileUpload:
      "Une erreur s'est produite lors du traitement de votre fichier. Veuillez réessayer ou choisir un autre fichier.",
  },
  [LANG.PT]: {
    CardHolder: 'Preencha o titular do cartão',
    InvalidIban: 'Número de conta IBAN inválido',
    FileUpload:
      'Something went wrong while handling your file. Please try again or choose another file.',
  },
};

const WARNINGS = {
  [LANG.NL]: {
    OFFER_INVALID: `Het aanbod dat je gekozen hebt is niet meer beschikbaar. Kies een andere leesformule.`,
    OFFER_INVALID_RENEWAL: `Het aanbod dat je gekozen hebt werd reeds geactiveerd. Kies een andere leesformule.`,
    SETTLEMENT_INVALID:
      'Je verlengaanbod is momenteel niet meer beschikbaar. Wellicht heb je al betaald? Zo niet neem contact op met onze klantenservice.',
    GENERAL: `Er ging iets mis met de afhandeling van je gekozen formule. Probeer opnieuw of kies een andere formule.`,
    NO_INTERNET: `Geen internet connectie gevonden. Verbind eerst met het internet voor je verder gaat.`,
  },
  [LANG.DE]: {
    OFFER_INVALID:
      'Das ausgewählte Angebot ist nicht mehr verfügbar. Wählen Sie eine andere Ableseformel.',
    OFFER_INVALID_RENEWAL:
      'Das ausgewählte Angebot wurde bereits aktiviert. Wählen Sie eine andere Ableseformel.',
    SETTLEMENT_INVALID:
      'Ihr Verlängerungsangebot ist derzeit nicht verfügbar. Vielleicht haben Sie bereits bezahlt? Falls nicht, wenden Sie sich bitte an unseren Kundenservice.',
    GENERAL:
      'Bei der Verarbeitung Ihrer ausgewählten Formel ist etwas schief gelaufen. Versuchen Sie es erneut oder wählen Sie eine andere Formel.',
    NO_INTERNET:
      'Keine Internetverbindung gefunden. Bitte verbinden Sie sich mit dem Internet, bevor Sie fortfahren.',
  },
  [LANG.EN]: {
    OFFER_INVALID:
      'The offer you have chosen is no longer available. Please choose another formula.',
    OFFER_INVALID_RENEWAL:
      'The offer you have chosen has already been activated. Please choose another formula.',
    SETTLEMENT_INVALID:
      'Your renewal offer is currently unavailable. Maybe you have already paid? If not please contact our customer service.',
    GENERAL:
      'Something went wrong while processing your chosen formula. Please try again or choose another formula.',
    NO_INTERNET:
      'No internet connection found. Please connect to the internet before continuing.',
  },
  [LANG.FR]: {
    OFFER_INVALID:
      "L'offre que vous avez choisie n'est plus disponible. Veuillez choisir une autre formule.",
    OFFER_INVALID_RENEWAL:
      "L'offre que vous avez choisie a déjà été activée. Veuillez choisir une autre formule.",
    SETTLEMENT_INVALID:
      "Votre offre de renouvellement est actuellement indisponible. Peut-être avez-vous déjà payé ? Si ce n'est pas le cas, veuillez contacter notre service clientèle.",
    GENERAL:
      "Une erreur s'est produite lors du traitement de la formule que vous avez choisie. Veuillez réessayer ou choisir une autre formule.",
    NO_INTERNET:
      'Aucune connexion internet trouvée. Veuillez vous connecter à internet avant de continuer.',
  },
  [LANG.PT]: {
    OFFER_INVALID:
      'A opção escolhida já não se encontra disponível. Por favor escolha outra opção.',
    OFFER_INVALID_RENEWAL: '',
    SETTLEMENT_INVALID:
      'A sua oferta de renovação está actualmente indisponível. Talvez já tenha pago? Se não, por favor contacte o nosso serviço ao cliente',
    GENERAL:
      'Algo correu mal ao processar o seu pedido. Por favor tente novamente ou escolha outra opção.',
    NO_INTERNET:
      'No internet connection found. Please connect to the internet before continuing.',
  },
};

const base = {
  BANNERS,
  PAYMENTS,
  ERRORS,
  WARNINGS,
};

export default base;
