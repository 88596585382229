import { Loader, Select } from '@mediahuis/chameleon-react';
import { useEffect, useState } from 'react';
import { getI18n } from 'react-i18next';

import { useCheckoutContext } from '~/context/checkout';
import { getCountriesWithLanguage } from '~/utils/getCountries';
import language from './language';

const SelectCountry = ({ values, onChange, disabled = false }) => {
  const { language: lang } = getI18n();
  const translation = language[lang];

  const { validations } = useCheckoutContext();

  const [countries, setCountries] = useState(null);

  const { addressAreaValidation } = validations;
  const allowedCountries = addressAreaValidation[0]?.countries;

  const isDisabled =
    (allowedCountries && allowedCountries.length <= 1) || disabled;

  useEffect(() => {
    getCountriesWithLanguage({
      countries: allowedCountries,
      onSuccess: response => {
        setCountries(response);
      },
    });
  }, [allowedCountries]);

  if (!countries) {
    return <Loader />;
  }

  return (
    <Select
      data-testid="country"
      disabled={isDisabled}
      id="country"
      label={translation.country}
      value={values.country}
      onChange={e => onChange(e.target.value)}
    >
      {countries.map(country => (
        <option key={country.IsoCode} value={country.IsoCode}>
          {country.Name}
        </option>
      ))}
    </Select>
  );
};

export default SelectCountry;
