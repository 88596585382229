import { getI18n } from 'react-i18next';
import { SETTLEMENT } from '~/constants';
import { getCheckout30, getSettlement30 } from '~/constants/checkout';

const getCheckoutId = checkoutFlow => {
  const { language: lang } = getI18n();
  switch (checkoutFlow) {
    case SETTLEMENT:
      return getSettlement30(lang)?.id;
    default:
      return getCheckout30(lang)?.id;
  }
};

export default getCheckoutId;
