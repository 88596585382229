import { getI18n } from 'react-i18next';
import { Caption } from '@mediahuis/chameleon-react';
import language from './language';

const AboPolicyLink = ({ isOneShot, isRecurring }) => {
  const { language: selectedLanguage } = getI18n();
  const { AboPolicyDefault, AboPolicyOneShot, AboPolicyRecurring } = language[
    selectedLanguage
  ];

  const renderAboPolicy = () => {
    if (isOneShot) {
      return <AboPolicyOneShot />;
    }
    if (isRecurring) {
      return <AboPolicyRecurring />;
    }

    return <AboPolicyDefault />;
  };

  return <Caption>{renderAboPolicy()}</Caption>;
};

export default AboPolicyLink;
