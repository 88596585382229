const base = {
  [LANG.NL]: {
    managePrivacySettings: 'Privacy voorkeuren beheren',
  },
  [LANG.DE]: {
    managePrivacySettings: 'Datenschutzeinstellungen verwalten',
  },
  [LANG.EN]: {
    managePrivacySettings: 'Manage privacy settings',
  },
  [LANG.FR]: {
    managePrivacySettings: 'Gérer les paramètres de confidentialité',
  },
  [LANG.PT]: {
    managePrivacySettings: 'Gerir configurações de privacidade',
  },
};

export default base;
