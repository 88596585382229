import axios from 'axios';
import { getI18n } from 'react-i18next';

const apiCall = (url, { headers, onSuccess, onError, params, ...config }) => {
  const { language } = getI18n();
  const searchParams = new URLSearchParams(url.substr(url.indexOf('?')));

  return axios({
    url,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    params: {
      ...(!searchParams?.has('lang') && { lang: language }),
      ...params,
    },
    ...config,
  })
    .then(response => onSuccess(response.data))
    .catch(error => {
      if (onError) {
        return onError(error);
      }
      return error;
    });
};
export default apiCall;
