/* eslint-disable no-console */
import { getSearchParams } from '~/utils/constants';
import { checkUtmParameters } from '~/utils/tracking/utmParameters';
import { UTM_ARTICLE_ID, MH_APPLICATION_NAME, GOOGLE_BILLING_TOKEN } from '~/constants';
import getCookie from '~/utils/getCookie';

const setupCMP = ({ globalState, setGlobalState }) => {
  console.log('setupCMP', MH_ENV);

  const utmData = {};
  const trackingData = {};

  const omgRequestIdKey = 'sub_id';
  const daisyconIdKey = 'daisycon_id';
  const googleBilling = 'externalTransactionToken';
  const utmDataPrefix = 'utm_';
  const queryDataPrefix = 'data_';

  const searchParams = getSearchParams();
  const referrerParams = new URLSearchParams(
    (document.referrer && document.referrer.split('?')[1]) || '',
  );

  for (const key of searchParams.keys()) {
    if (key === omgRequestIdKey || key === daisyconIdKey) {
      utmData[key] = searchParams.get(key);

      if (key === daisyconIdKey) {
        document.cookie = `daisycon_id=${searchParams.get(key)};${new Date().getTime() + 604800000
          };path=/`;
      }
    } else if (key === UTM_ARTICLE_ID) {
      sessionStorage.setItem(UTM_ARTICLE_ID, searchParams.get(key));
    } else if (key === MH_APPLICATION_NAME) {
      const applicationName = searchParams.get(key);
      sessionStorage.setItem(MH_APPLICATION_NAME, applicationName);
    } else if (key === googleBilling) {
      const token = searchParams.get(key);
      sessionStorage.setItem(GOOGLE_BILLING_TOKEN, token);
    }
    if (key.startsWith(queryDataPrefix)) {
      const replacedKey = key.replace(queryDataPrefix, '');
      trackingData[replacedKey] = searchParams.get(key);
    } else if (key.startsWith(utmDataPrefix)) {
      const value = searchParams.get(key);
      utmData[key] = value;
      trackingData[key] = value;
    }
  }

  if (!sessionStorage.getItem(UTM_ARTICLE_ID)) {
    const lastPaywallArticleId = getCookie("lastPaywallArticleId");
    if (lastPaywallArticleId) {
      sessionStorage.setItem(UTM_ARTICLE_ID, lastPaywallArticleId);
      utmData[UTM_ARTICLE_ID] = lastPaywallArticleId;
    }
  }

  for (const key of referrerParams.keys()) {
    if (key.startsWith(utmDataPrefix)) {
      utmData[`ref_${key}`] = referrerParams.get(key);
    }
  }

  checkUtmParameters(utmData);

  // this ensures a proper functioning aboshop after 5 seconds
  // in case the consent platform is not available at the moment

  if (globalState.staticRender) {
    console.log('CMP staticRender');
    setGlobalState({ cmpLoaded: true });
    setTimeout(() => {
      setGlobalState({ cmpLoaded: true });
    }, 2000);
  } else {
    console.log('CMP setTimeout');
    setTimeout(() => {
      setGlobalState({ cmpLoaded: true });
    }, 5000);
  }

  setGlobalState({ trackingData, utmData });
};

export default setupCMP;
