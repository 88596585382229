import styled from 'styled-components';
import {
  fontWeightMedium,
  fontSizeSmall,
  colorBlackBase,
  fontFamilySystem,
} from '@mediahuis/chameleon-theme-wl';

export const InputLabel = styled.label`
  display: block;
  font-weight: ${fontWeightMedium};
  font-size: ${fontSizeSmall};
  color: ${colorBlackBase};
  font-family: ${fontFamilySystem};
`;

export default InputLabel;
