import { Box } from '@mediahuis/chameleon-react';
import styled from 'styled-components';
import IframeResizer from 'iframe-resizer-react';
import { isMobile } from 'react-device-detect';
import { EMBEDDED_BROWSER } from '../constants';

const HtmlContent = styled(Box)`
  width: ${() =>
    isMobile
      ? '100vw'
      : 'calc(100vw - 9px)'}; /* 9px will ensure that a horizontal scrollbar is hidden */
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
`;

const HtmlRenderer = ({ offerHtmlContent, position }) => {
  const isIosWebView = navigator.userAgent.indexOf(EMBEDDED_BROWSER) !== -1;
  const combinedHtml = offerHtmlContent.map(item => item.content.value);

  if (isIosWebView) {
    return null;
  }

  return (
    <HtmlContent id={`htmlContent-${position}`}>
      <IframeResizer
        id={position}
        title={position}
        style={{
          width: '1px',
          minWidth: '100%',
          height: '0px',
          minHeight: '0px',
        }}
        checkOrigin={false}
        srcDoc={`<div id="aboshop-html-wrapper-${position}">${combinedHtml.join(
          '',
        )}</div><script type='text/javascript' src='https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.6/iframeResizer.contentWindow.min.js'></script>`}
      />
    </HtmlContent>
  );
};

export default HtmlRenderer;
