import { Heading, Paragraph } from '@mediahuis/chameleon-react';

const nlBase = {
  Title: props => <Heading {...props}>Onder constructie</Heading>,
  Subtitle: props => (
    <Paragraph {...props}>
      Beste klant, bedankt dat je interesse hebt in onze nieuwsmerken. Momenteel
      zijn we druk aan het werk om je later nog beter van dienst te kunnen zijn.
      Gelieve later opnieuw te proberen.
    </Paragraph>
  ),
};

const deBase = {
  Title: props => <Heading {...props}>Im Bau</Heading>,
  Subtitle: props => (
    <Paragraph {...props}>
      Sehr geehrte Kundin, sehr geehrter Kunde, wir danken Ihnen für Ihr
      Interesse an unseren Nachrichtenmarken. Wir arbeiten derzeit hart daran,
      Ihnen später einen noch besseren Service zu bieten. Bitte versuchen Sie es
      später noch einmal.
    </Paragraph>
  ),
};

const enBase = {
  Title: props => <Heading {...props}>Under construction</Heading>,
  Subtitle: props => (
    <Paragraph {...props}>
      Dear customer, thank you for your interest in our news brands. We are
      currently working hard to provide you with even better service later.
      Please try again later.
    </Paragraph>
  ),
};

const frBase = {
  Title: props => <Heading {...props}>En construction</Heading>,
  Subtitle: props => (
    <Paragraph {...props}>
      Cher client, merci de l&apos;intérêt que vous portez à nos nouvelles
      marques. Nous travaillons actuellement d&apos;arrache-pied pour vous
      fournir un service encore meilleur par la suite. Veuillez réessayer plus
      tard.
    </Paragraph>
  ),
};

const ptBase = {
  Title: props => <Heading {...props}>Em construção</Heading>,
  Subtitle: props => (
    <Paragraph {...props}>
      Caro cliente, obrigado pelo seu interesse nas nossas marcas de notícias.
      Estamos actualmente a trabalhar arduamente para lhe fornecer um serviço
      ainda melhor mais tarde. Por favor, tente novamente mais tarde.
    </Paragraph>
  ),
};

const base = {
  [LANG.NL]: nlBase,
  [LANG.DE]: deBase,
  [LANG.EN]: enBase,
  [LANG.FR]: frBase,
  [LANG.PT]: ptBase,
};

export default base;
