import styled from 'styled-components';

const Switcher = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${props => (props.space ? props.space : '1rem')};

  & > * {
    flex-grow: 1;
    flex-basis: calc(
      (${props => (props.threshold ? props.threshold : '30rem')} - 100%) * 999
    );
  }
  & > :nth-last-child(n + ${props => props.limit + 1}),
  & > :nth-last-child(n + ${props => props.limit + 1}) ~ * {
    flex-basis: 100%;
  }
`;

export default Switcher;
