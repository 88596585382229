import { tracker as mhTracker, whoami } from '@mediahuis/tracking-poc';

function initializeTracker() {
  let trackerConfig;
  whoami(TRACKING_ENV, MH_BRAND, MH_ENV || 'dev')
    .then(cfg => {
      (window.didomiOnReady = window.didomiOnReady || []).push(() => {
        // set timeout to 0 to avoid that this code executes before didomi properties are set
        setTimeout(() => {
          window.mht = mhTracker({
            entity: TRACKING_ENV,
            brandCode: MH_BRAND,
            // TODO remove in future (but no local dev detection for now)
            environment: MH_ENV || 'dev',
          });
        }, 0);
        trackerConfig = cfg.tagManager.type;
        window.mht.trackerConfig = trackerConfig;
        return cfg;
      });
      // trackerConfig = cfg.tagManager.type;
      // window.mht.trackerConfig = trackerConfig;
      return cfg;
    })
    .catch(error => error);
}

export default initializeTracker;
