import { RedirectToSignIn as RedirectToSignInLux } from '@subscriber/authentication-lux';
import {
  CIAM_IDENTITY_LEVEL,
  RedirectToRegister as RedirectToRegisterCiam,
  RedirectToSignIn as RedirectToSignInCiam,
} from '@subscriber/ciam';
import { useEffect, useState } from 'react';

import PageLoader from '~/components/PageLoader';
import { useCheckoutContext } from '~/context/checkout';
import { useGlobalContext } from '~/context/global';
import updateGlobalStateWithUserData from '~/utils/updateGlobalStateWithUserData';

import { AUTHENTICATION_STATUS } from '../../enums';
import { useAuthentication } from '../../hooks/useAuthentication';
import { Authentication } from '../Authentication';
import { SignedIn } from '../SignedIn';
import { SignedOut } from '../SignedOut';

const RedirectToCiam = () => {
  const { validations } = useCheckoutContext();
  const redirectOptions = {
    registerIdentityLevel: validations?.loginValidation?.identityLevel || CIAM_IDENTITY_LEVEL.MH5,
    returnTo: window.location.href,
    skipEmailVerification: '1',
  };



  if (MH_BRAND === 'dl') {
    return <RedirectToRegisterCiam options={redirectOptions} />;
  }

  return <RedirectToSignInCiam options={redirectOptions} />;
};

const AuthenticationGate = ({ children }) => {
  const { authenticationStatus, userInfo } = useAuthentication();
  const {
    setInitiallyHasFirstName,
    setInitiallyHasName,
  } = useCheckoutContext();
  const { setGlobalState } = useGlobalContext();

  const [isGlobalStateReady, setIsGlobalStateReady] = useState(false);

  const isCiamAuthenticated = MH_GROUP === 'BE' || MH_GROUP === 'DL';
  const isLuxAuthenticated = MH_GROUP === 'LUX';

  useEffect(() => {
    if (authenticationStatus === AUTHENTICATION_STATUS.AUTHENTICATED) {
      updateGlobalState(userInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationStatus]);

  function updateGlobalState(user) {
    updateGlobalStateWithUserData({
      user,
      setGlobalState,
      setInitiallyHasFirstName,
      setInitiallyHasName,
    });
    setIsGlobalStateReady(true);
  }

  return (
    <Authentication>
      <SignedIn>{isGlobalStateReady ? children : <PageLoader />}</SignedIn>

      <SignedOut>
        {isCiamAuthenticated && <RedirectToCiam />}

        {isLuxAuthenticated && <RedirectToSignInLux />}
      </SignedOut>
    </Authentication>
  );
};

export default AuthenticationGate;
