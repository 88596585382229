import { LinkText } from '@mediahuis/chameleon-react';

const nlBase = {
  AboPolicyDefault: () => (
    <>
      Ik heb gelezen en aanvaard de{' '}
      <LinkText
        href="https://www.mediahuis.be/nl/abonnementsvoorwaarden/"
        target="_blank"
        style={{ textDecoration: 'underline ' }}
      >
        algemene abonnementsvoorwaarden
      </LinkText>{' '}
      die onder meer voorzien in een herroepingsrecht.
    </>
  ),
  AboPolicyOneShot: () => (
    <>
      Ik heb gelezen en aanvaard de{' '}
      <LinkText
        href="https://www.mediahuis.be/nl/abonnementsvoorwaarden/"
        target="_blank"
        style={{ textDecoration: 'underline ' }}
      >
        algemene abonnementsvoorwaarden
      </LinkText>{' '}
      die onder meer voorzien in een herroepingsrecht.
    </>
  ),
  AboPolicyRecurring: () => (
    <>
      Ik heb gelezen en aanvaard de{' '}
      <LinkText
        href="https://www.mediahuis.be/nl/abonnementsvoorwaarden/"
        target="_blank"
        style={{ textDecoration: 'underline ' }}
      >
        algemene abonnementsvoorwaarden
      </LinkText>{' '}
      die onder meer voorzien in een herroepingsrecht.
    </>
  ),
};

const base = {
  [LANG.NL]: nlBase,
  [LANG.DE]: nlBase,
  [LANG.EN]: nlBase,
  [LANG.FR]: nlBase,
  [LANG.PT]: nlBase,
};

export default base;
