import apiCall from './apiCall';

const getHtmlContent = ({ key, onSuccess, onError }) =>
  apiCall(`${CONTENT_SERVICE}/ContentLinks?brand=${MH_BRAND}&key=${key}`, {
    method: 'GET',
    onSuccess: response => {
      if (onSuccess) {
        return onSuccess(response);
      }
      return response;
    },
    onError: error => {
      if (onError) {
        return onError(error);
      }
      return error;
    },
  });

export default getHtmlContent;
