import { getI18n } from 'react-i18next';
import { DidomiSDK } from '@didomi/react';
import { USER_LANGUAGE } from '~/constants';
import { useGlobalContext } from '~/context/global';
import normaliseConsentData from './normaliseConsentData';

const Didomi = () => {
  const { setGlobalState } = useGlobalContext();
  const { language } = getI18n();
  const localStorageLanguage = localStorage.getItem(USER_LANGUAGE);

  return (
    <DidomiSDK
      apiKey="11ef8ac9-6270-4d5e-8b99-8d6a5bd60059"
      iabVersion={2}
      gdprAppliesGlobally={true}
      config={{
        user: {
          bots: {
            consentRequired: false,
            types: ['crawlers', 'performance'],
            extraUserAgents: ['mh_monitoring'],
          },
        },
        languages: {
          enabled: localStorageLanguage
            ? [localStorageLanguage]
            : POSSIBLE_LANGUAGES[MH_BRAND].map(l => l.value),
          default: localStorageLanguage || language,
        },
      }}
      onReady={didomi => {
        window.gdprConsents = normaliseConsentData(didomi);
        // set timeout to 0 to avoid that this code executes before didomi properties are set
        setTimeout(() => setGlobalState({ cmpLoaded: true }), 0);
      }}
      onConsentChanged={() => {
        window.gdprConsents = normaliseConsentData(window.Didomi);
      }}
      on
    />
  );
};

export default Didomi;
