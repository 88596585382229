import { useState, useContext, createContext } from 'react';

const OfferContext = createContext();

const OfferProvider = ({ children }) => {
  const [offer, setOffer] = useState({});
  const [offerType, setOfferType] = useState({});
  const [offerFormula, setOfferFormula] = useState({});

  const resetOfferState = () => {
    setOffer({});
    setOfferType({});
    setOfferFormula({});
  };

  const value = {
    offer,
    offerType,
    offerFormula,
    setOfferState: setOffer,
    resetOfferState,
    setOfferType,
    setOfferFormula,
  };

  return (
    <OfferContext.Provider value={value}>{children}</OfferContext.Provider>
  );
};

const useOfferContext = () => {
  const context = useContext(OfferContext);

  if (context === undefined) {
    throw new Error('useOfferContext must be used within an OfferProvider');
  }

  return context;
};

export { OfferProvider, useOfferContext };
