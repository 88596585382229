import base from './base';

const language = {
  ...base,
  [LANG.NL]: {
    ...base[LANG.NL],
    customerServicePhoneNumber: '02 790 21 15',
    mediahuisText: 'Nieuwsblad is een sterk nieuwsmerk van',
  },
};

export default language;
