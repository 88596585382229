const defaultGlobalState = {
  superSecretMode: true,
  user: {
    accountGuid: undefined,
    firstName: '',
    name: '',
    country: '',
    postalCode: '',
    city: '',
    street: '',
    houseNumber: '',
    box: '',
  },
  address: {
    allowedCountries: null,
    personal: {
      firstName: '',
      name: '',
      country: '',
      postalCode: '',
      city: '',
      street: '',
      houseNumber: '',
      box: '',
    },
    pickup: {
      country: '',
      postalCode: '',
      city: '',
      street: '',
      houseNumber: '',
      box: '',
      deliveryStore: '',
      shopId: null,
    },
  },
  date: {},
  invoice: {
    vatNumber: '',
    companyName: '',
    firstName: '',
    name: '',
    country: '',
    postalCode: '',
    city: '',
    street: '',
    houseNumber: '',
    box: '',
  },
  uploads: {
    pdf: null,
    image: null,
    file: null,
  },
  pickupSelected: false,
  trackingData: {},
  paymentStatus: null,
  checkoutFlow: null,
  settlementReference: null,
  UI: { extraFooterHeight: 0 },
  staticRender: false,
};

export default defaultGlobalState;
