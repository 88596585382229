const base = {
  [LANG.NL]: {
    contactCustomerService: 'Contacteer klantenservice',
    footerTitle: 'Hulp nodig?',
    footerContactPhoneDesktop:
      'Neem telefonisch contact op met onze klantenservice via het nummer',
    footerContactPhoneClosingHourDesktop: 'Neem tot 17u contact op',
    privacyPolicy: 'Privacy Policy',
    privacyPolicyUrl: 'https://www.mediahuis.be/privacy-policy',
    customerServicePhoneNumber: '03 303 30 37',
    customerServiceEmail: null,
    mediahuisText: 'GVA is een sterk nieuwsmerk van',
  },
  [LANG.DE]: {
    contactCustomerService: 'Kundendienst kontaktieren',
    footerTitle: 'Brauchen Sie Hilfe?',
    footerContactPhoneDesktop:
      'Kontaktieren Sie unseren Kundenservice telefonisch unter der Nummer',
    footerContactPhoneClosingHourDesktop: 'Kontaktieren Sie uns bis 17:00 Uhr',
    privacyPolicy: 'Datenschutzerklärung',
    privacyPolicyUrl: 'https://www.wort.lu/de/privacy',
    customerServicePhoneNumber: '+352/4993-9393',
    customerServiceEmail: null,
  },
  [LANG.EN]: {
    contactCustomerService: 'Contact customer service',
    footerTitle: '',
    footerContactPhoneDesktop:
      'If you have any questions, our customer service will be happy to help you.',
    footerContactPhoneClosingHourDesktop: 'Monday to Friday, 8:00 a.m. to 5:00 p.m.',
    customerServicePhoneNumber: '(+352) 49 93 93 93',
    customerServiceEmail: 'support@luxtimes.lu',
    privacyPolicy: 'Privacy policy',
    privacyPolicyUrl: 'https://www.wort.lu/en/privacy',
  },
  [LANG.FR]: {
    contactCustomerService: 'Contacter le service clientèle',
    footerTitle: "Besoin d'aide?",
    footerContactPhoneDesktop: 'Contactez notre service clientèle au numéro',
    footerContactPhoneClosingHourDesktop: "Contactez nous jusqu'à 17:00 heures",
    privacyPolicy: 'Politique de confidentialité',
    privacyPolicyUrl: 'https://www.wort.lu/fr/privacy',
    customerServicePhoneNumber: '+352/4993-9393',
    customerServiceEmail: null,
},
  [LANG.PT]: {
    contactCustomerService: 'Contactar o serviço de cliente',
    footerTitle: 'Precisa de ajuda?',
    footerContactPhoneDesktop: 'Contacte o nosso serviço de apoio ao cliente:',
    footerContactPhoneClosingHourDesktop:
      'Atendimento em francês, alemão e luxemburguês (Disponível em dias úteis, das 08h às 17h.)',
    privacyPolicy: 'Política de privacidade',
    privacyPolicyUrl: 'https://www.wort.lu/pt/privacy',
    customerServicePhoneNumber: '+352/4993-9393',
    customerServiceEmail: null,
  },
};

export default base;
