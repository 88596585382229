import {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { USER_LANGUAGE } from '~/constants';
import setupCMP from './utils/setupCMP';
import defaultGlobalState from './constants/defaultGlobalState';

const GlobalContext = createContext();

const GlobalProvider = ({ staticRender, children }) => {
  const { i18n } = useTranslation();

  const [globalState, setState] = useState({
    ...defaultGlobalState,
    staticRender,
  });

  const setGlobalState = useCallback(
    data => setState(state => ({ ...state, ...data })),
    [setState],
  );

  const setUIState = useCallback(
    data => setState(state => ({ ...state, UI: { ...state.UI, ...data } })),
    [setState],
  );

  const setLanguage = e => {
    const value = e.target.value;
    i18n?.changeLanguage(value);
    if (POSSIBLE_LANGUAGES[MH_BRAND].length > 1) {
      localStorage.setItem(USER_LANGUAGE, value);
    }
  };

  const value = { ...globalState, setGlobalState, setUIState, setLanguage };

  useEffect(() => {
    setupCMP({ globalState, setGlobalState });

    const languages = POSSIBLE_LANGUAGES[MH_BRAND];

    if (languages.length > 1) {
      const userLang = localStorage.getItem(USER_LANGUAGE);
      const validLanguage =
        userLang && languages.some(l => l.value === userLang);
      if (validLanguage) {
        i18n?.changeLanguage(userLang);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

const useGlobalContext = () => {
  const context = useContext(GlobalContext);

  if (context === undefined) {
    throw new Error('useGlobalContext must be used within a GlobalProvider');
  }

  return context;
};

export { GlobalProvider, useGlobalContext };
