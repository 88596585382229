const format = (hasAlphaNumerical, validHouseNumber, value) => {
  return {
    isValidHouseNumber:
      !hasAlphaNumerical ||
      (validHouseNumber && hasAlphaNumerical?.length === 1),
    formattedHouseNumber: validHouseNumber
      ? validHouseNumber[0]
      : value.replace(/\D/g, ''),
  };
};

const formatHouseNumber = value => {
  const hasAlphaNumerical = value.match(/[a-z]/g);
  const validHouseNumber = value.match(/\d+[a-zA-Z]/g);
  const validHouseNumberLux = value.match(/\d+[a-zA-Z]{0,4}/g);

  return format(
    hasAlphaNumerical,
    MH_GROUP === 'LUX' ? validHouseNumberLux : validHouseNumber,
    value,
  );
};

export default formatHouseNumber;
