export const tracker = () => {
  const mht = window.mht;

  if (!mht || Array.isArray(mht)) {
    return null;
  }

  return mht;
};

export const setDummyTracker = () => {
  window.mht = {
    push: '',
    trackingCalls: '',
    messages: '',
  };
};
