export const brandConfig = [
  {
    brand: 'ds',
    highlightColor: 'color-primary-base',
    highlightColorText: 'color-text-primary-base',
  },
  {
    brand: 'gva',
    highlightColor: 'color-marketing-base',
    highlightColorText: 'color-text-marketing-base',
  },
  {
    brand: 'hbvl',
    highlightColor: 'color-secondary-base',
    highlightColorText: 'color-text-secondary-base',
  },
  {
    brand: 'nb',
    highlightColor: 'color-secondary-base',
    highlightColorText: 'color-text-secondary-base',
  },
];
